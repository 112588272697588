import { Builder } from '@builder.io/react';
import React from 'react';

import headerAlignmentFontInputs, {
  Alignment,
  FontFamily,
} from '../../utils/headerAlignmentFontInputs';
import CallToAction, {
  callToActionInputs,
  CallToActionProps,
} from '../elements/CallToAction.builder';
import PillBuilder from '../elements/Pill.builder';
import BuilderSection from '../layouts/BuilderSection';

interface Pill {
  href: string;
  text: string;
}

interface PillsProps {
  heading: string;
  headingAlignment: Alignment;
  fontFamily: FontFamily;
  pillAlignment: string;
  pills: Pill[];
  browseAllPill?: Pill;
  showCallToAction: boolean;
  callToAction?: CallToActionProps;
}

const Pills = ({
  heading,
  headingAlignment,
  fontFamily,
  pillAlignment,
  pills,
  browseAllPill,
  showCallToAction,
  callToAction,
}: PillsProps) => (
  <BuilderSection
    className={headingAlignment}
    heading={heading}
    headingClassNames={`${fontFamily} text-5xl`}
  >
    <>
      <div
        className={`gap-x-2 gap-y-4 flex flex-row flex-wrap items-center mb-8 ${pillAlignment}`}
      >
        {pills.map(({ href, text }, index) => (
          <PillBuilder key={`pill-${index}-${text}`} href={href} text={text} />
        ))}

        {browseAllPill && (
          <PillBuilder
            href={browseAllPill.href}
            text={browseAllPill.text}
            className="font-bold"
          />
        )}
      </div>
      <CallToAction showCallToAction={showCallToAction} {...callToAction} />
    </>
  </BuilderSection>
);

Builder.registerComponent(Pills, {
  name: 'Pills',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: '[Heading]',
    },
    ...headerAlignmentFontInputs,
    {
      name: 'pillAlignment',
      type: 'string',
      required: true,
      defaultValue: 'justify-center',
      enum: [
        {
          label: 'Left',
          value: 'justify-start',
        },
        {
          label: 'Center',
          value: 'justify-center',
        },
      ],
    },
    {
      name: 'pills',
      type: 'list',
      required: true,
      defaultValue: [{ href: '/', text: 'Pill' }],
      subFields: [
        {
          name: 'href',
          type: 'string',
          required: true,
          defaultValue: '/',
        },
        {
          name: 'text',
          type: 'string',
          required: true,
          defaultValue: 'Pill',
        },
      ],
    },
    {
      name: 'browseAllPill',
      type: 'object',
      subFields: [
        {
          name: 'href',
          type: 'string',
          defaultValue: '/video/search',
        },
        {
          name: 'text',
          type: 'string',
          defaultValue: 'Browse All',
        },
      ],
    },
    ...callToActionInputs,
  ],
});

export default Pills;
