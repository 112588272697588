import { Builder } from '@builder.io/react';
import { withChildren } from '@builder.io/react';
import { PropsWithChildren } from 'react';
import React from 'react';

import { Alert as StorywindAlert } from '@videoblocks/storywind';
import { AlertSeverity } from '@videoblocks/storywind/components/Alert';

interface AlertProps {
  severity: AlertSeverity;
  icon: boolean;
}

const Alert = withChildren(function Alert({
  severity,
  icon,
  children,
}: PropsWithChildren<AlertProps>) {
  return (
    <StorywindAlert severity={severity} icon={icon}>
      {children}
    </StorywindAlert>
  );
});

Builder.registerComponent(Alert, {
  name: 'Alert',
  inputs: [
    {
      name: 'severity',
      type: 'string',
      defaultValue: 'success',
      enum: [
        {
          label: 'Error',
          value: 'error',
        },
        {
          label: 'Info',
          value: 'info',
        },
        {
          label: 'Neutral',
          value: 'neutral',
        },
        {
          label: 'Success',
          value: 'success',
        },
        {
          label: 'Warning',
          value: 'warning',
        },
      ],
    },
    {
      name: 'icon',
      type: 'boolean',
      defaultValue: true,
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Text goes here.' } },
    },
  ],
});
