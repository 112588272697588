import { Builder } from '@builder.io/react';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import {
  ProgressiveImage,
  ButtonColor,
  ButtonVariant,
  ButtonSize,
  Button,
} from '@videoblocks/storywind';

import getAssetSource from '../../../app/Search/utils/getAssetSource';
import { lazyLoadDefaultConfig, opacityOptions } from '../../constants';
import CallToAction, {
  callToActionInputs,
  CallToActionProps,
  secondaryCallToActionInputs,
} from '../elements/CallToAction.builder';

enum TextColor {
  white = 'text-white',
  gray = 'text-gray-900',
}

interface AuxiliaryButtonsProps {
  color: ButtonColor;
  href: string;
  size: ButtonSize;
  variant: ButtonVariant;
  text: string;
}

interface Opacity {
  percentage: number;
}

interface HeroProps {
  heading: string;
  subheading: string;
  imageSource: string;
  videoSource?: string;
  showCallToAction: boolean;
  callToAction?: CallToActionProps;
  showSecondaryCta: boolean;
  secondaryCta?: CallToActionProps;
  imageSourceAlt?: string;
  verticalAlignment?: 'middle' | 'bottom';
  horizontalAlignment?: 'left' | 'center';
  useOpaqueBackground: boolean;
  textColor: TextColor;
  lazyLoadImage: boolean;
  secondaryButtons: AuxiliaryButtonsProps[];
  opacity?: Opacity;
  tall?: boolean;
  backgroundColor?: string;
}

const AuxiliaryButtons = ({
  color,
  href,
  variant,
  text,
  size,
}: AuxiliaryButtonsProps) => {
  return (
    <CallToAction
      className="px-7"
      color={color}
      href={href}
      variant={variant}
      text={text}
      squared={false}
      showCallToAction={true}
      size={size}
    />
  );
};

const MediaHero = ({
  heading,
  subheading,
  imageSource,
  videoSource,
  showCallToAction,
  callToAction,
  showSecondaryCta,
  secondaryCta,
  imageSourceAlt = '',
  horizontalAlignment = 'center',
  verticalAlignment = 'middle',
  useOpaqueBackground,
  textColor = TextColor.white,
  lazyLoadImage,
  secondaryButtons,
  opacity,
  tall,
  backgroundColor,
}: HeroProps) => {
  const imageSrc = getAssetSource(imageSource);
  const videoSrc = getAssetSource(videoSource);

  return (
    <header
      className={twMerge(
        horizontalAlignment === 'center'
          ? 'px-6 md:px-4 justify-center'
          : 'pl-10',
        verticalAlignment === 'middle' ? 'py-36' : 'md:pt-60 md:pb-16',
        'flex relative w-full heroContainer-textContainer'
      )}
    >
      {videoSource ? (
        <div>
          <video
            className="lg:block absolute inset-0 hidden object-cover w-full h-full"
            autoPlay
            loop
            muted
            playsInline
            poster={imageSrc}
            preload="auto"
          >
            <source src={videoSrc} type="video/webm" />
            <source src={videoSrc} type="video/mp4" />
            <ProgressiveImage
              src={imageSrc}
              alt={imageSourceAlt}
              shouldLazyLoad={lazyLoadImage}
            />
          </video>
          <ProgressiveImage
            className="lg:hidden absolute inset-0 object-cover w-full h-full"
            src={imageSrc}
            alt={imageSourceAlt}
            shouldLazyLoad={lazyLoadImage}
          />
        </div>
      ) : (
        <ProgressiveImage
          className="absolute inset-0 object-cover w-full h-full"
          src={imageSrc}
          alt={imageSourceAlt}
          shouldLazyLoad={lazyLoadImage}
        />
      )}
      {useOpaqueBackground && (
        <div
          style={{ opacity: (opacity && opacity.percentage) || 0.45 }}
          className="absolute inset-0 w-full h-full bg-gray-900"
        />
      )}
      <div
        className={twMerge(
          verticalAlignment === 'middle' && 'md:py-4 md:px-0 md:pb-6 p-12',
          horizontalAlignment === 'center'
            ? 'text-center'
            : 'flex flex-col md:items-start',
          backgroundColor && 'rounded-xl md:px-4 py-4 md:py-6',
          tall && 'md:py-10',
          'relative block w-full space-y-6 max-w-3xl',
          textColor
        )}
        style={{ backgroundColor }}
      >
        <h1 className="w-full m-0 font-bold text-3xl lg:text-5xl">{heading}</h1>
        {subheading && (
          <h2
            className={twMerge(
              'font-base m-0 lg:text-xl text-base',
              tall && 'pb-4'
            )}
          >
            {subheading}
          </h2>
        )}
        {secondaryButtons?.length > 0 && (
          <div
            className={twMerge(
              'gap-x-4 gap-y-4 flex md:flex-row flex-col flex-wrap items-center mb-8 justify-center',
              horizontalAlignment === 'left' &&
                (backgroundColor ? '-ml-20' : '-ml-24')
            )}
          >
            {secondaryButtons.map((secondaryButtonProps, index) => (
              <AuxiliaryButtons key={index} {...secondaryButtonProps} />
            ))}
          </div>
        )}
        {showCallToAction && (
          <div
            className={twMerge(
              'flex',
              horizontalAlignment === 'center' && 'justify-center text-center'
            )}
          >
            <Button className="no-underline" {...callToAction}>
              {callToAction.text}
            </Button>
            {showSecondaryCta && (
              <Button className="no-underline ml-2" {...secondaryCta}>
                {secondaryCta.text}
              </Button>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

Builder.registerComponent(MediaHero, {
  name: 'Media Hero',
  inputs: [
    {
      name: 'verticalAlignment',
      type: 'string',
      required: false,
      defaultValue: 'middle',
      enum: [
        {
          label: 'Middle',
          value: 'middle',
        },
        {
          label: 'Bottom',
          value: 'bottom',
        },
      ],
    },
    {
      name: 'horizontalAlignment',
      type: 'string',
      required: false,
      defaultValue: 'center',
      enum: [
        {
          label: 'Left',
          value: 'left',
        },
        {
          label: 'Center',
          value: 'center',
        },
      ],
    },
    {
      name: 'useOpaqueBackground',
      type: 'boolean',
      required: false,
      defaultValue: false,
    },
    {
      name: 'opacity',
      type: 'object',
      defaultValue: {
        percentage: 0.45,
      },
      showIf: (options) => options.get('useOpaqueBackground'),
      subFields: [
        {
          name: 'percentage',
          type: 'string',
          required: true,
          enum: opacityOptions,
        },
      ],
    },
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: '[Heading]',
    },
    {
      name: 'subheading',
      type: 'longText',
      required: false,
      defaultValue: '[Subheading]',
    },
    {
      name: 'videoSource',
      // https://www.builder.io/c/docs/custom-components-input-types#code-strong-file-strong-code
      type: 'file',
      required: false,
      allowedFileTypes: ['webm', 'mp4'],
      defaultValue: '/assets/common/videos/placeholder.webm',
    },
    {
      name: 'imageSource',
      type: 'file',
      required: true,
      allowedFileTypes: ['jpg', 'png', 'webp'],
      defaultValue: '/assets/common/images/placeholder.png',
    },
    {
      name: 'imageSourceAlt',
      type: 'string',
      helperText: 'Image alternative text',
    },
    {
      ...lazyLoadDefaultConfig,
    },
    {
      name: 'tall',
      type: 'boolean',
      required: false,
      helperText: 'Tall vs. short widget',
      defaultValue: true,
    },
    {
      name: 'textColor',
      required: true,
      type: 'string',
      enum: [
        {
          label: 'gray',
          value: TextColor.gray,
        },
        {
          label: 'white',
          value: TextColor.white,
        },
      ],
      defaultValue: TextColor.white,
    },
    {
      name: 'backgroundColor',
      type: 'color',
      required: false,
      defaultValue: '#ffffff00',
    },
    {
      name: 'secondaryButtons',
      type: 'list',
      onChange: (options) => {
        if (options.get('secondaryButtons').length > 4) {
          options.set(
            'secondaryButtons',
            options.get('secondaryButtons').slice(0, 4)
          );
          alert("can't be more than 4");
        }
      },
      subFields: [
        {
          name: 'text',
          type: 'string',
          defaultValue: '[Label]',
          required: true,
        },
        {
          name: 'href',
          type: 'string',
          defaultValue: '/',
          required: true,
        },
        {
          name: 'color',
          type: 'string',
          defaultValue: 'secondary',
          enum: ['primary', 'secondary', 'light'],
        },
        {
          name: 'size',
          type: 'string',
          defaultValue: 'small',
          enum: ['small', 'medium', 'large'],
        },
        {
          name: 'variant',
          type: 'string',
          defaultValue: 'outlined',
          enum: ['contained', 'outlined'],
        },
      ],
    },
    ...callToActionInputs,
    ...secondaryCallToActionInputs,
  ],
});
