import { Builder } from '@builder.io/react';
import React from 'react';

import { NewTag as StorywindNewTag } from '@videoblocks/storywind';

function NewTag() {
  return <StorywindNewTag />;
}

Builder.registerComponent(NewTag, {
  name: 'New tag',
});
