import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useDebouncedCallback } from 'use-debounce';

import { CarouselItemPropTypes } from './CarouselItem';
import SlideButton, { SlideButtonType } from './SlideButton';

export interface CarouselPropTypes {
  children: ReactElement<CarouselItemPropTypes>[];
  numberItemsToShow?: number;
  className?: string;
  overflow?: boolean;
}

const Carousel = ({
  children,
  numberItemsToShow = 3,
  className,
  overflow = false,
}: CarouselPropTypes): ReactElement => {
  const calculateNumberItemsToShow = (width) =>
    width < 768 ? 1 : numberItemsToShow;

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [numberItemsToShowCalculated, setNumberItemsToShowCalculated] =
    useState<number>(calculateNumberItemsToShow(window.innerWidth));
  const length = children.length;

  const debouncedResizeHandler = useDebouncedCallback(
    () => {
      setNumberItemsToShowCalculated(
        calculateNumberItemsToShow(window.innerWidth)
      );
    },
    350,
    { maxWait: 1000 }
  );

  useEffect(() => {
    window.addEventListener('resize', debouncedResizeHandler);
    return () => {
      window.removeEventListener('resize', debouncedResizeHandler);
    };
  }, [debouncedResizeHandler]);

  useEffect(() => {
    setCurrentPage(0);
  }, [numberItemsToShowCalculated]);

  const isNextDisabled = useMemo(() => {
    return (currentPage + 1) * numberItemsToShowCalculated >= length;
  }, [length, currentPage]);

  const isPrevDisabled = useMemo(() => {
    return currentPage === 0;
  }, [currentPage]);

  const next = () => {
    setCurrentPage((prevState) => prevState + 1);
  };

  const prev = () => {
    setCurrentPage((prevState) => prevState - 1);
  };

  return (
    <div className="carousel-wrapper flex w-full relative">
      {!isPrevDisabled && (
        <SlideButton
          className="absolute left-0 bottom-1/2 transform translate-y-1/2 z-10"
          type={SlideButtonType.BACK}
          onClick={prev}
        />
      )}
      <div
        className={twMerge(
          'carousel w-full h-full',
          overflow ? 'overflow-visible fadeEdges' : 'overflow-hidden'
        )}
      >
        <div
          className={twMerge(
            className,
            `carousel-content transition-all whitespace-nowrap`
          )}
          style={{ transform: `translateX(-${currentPage * 100}%)` }}
          data-testid="carousel-content"
        >
          {React.Children.map(children, (child, index) => {
            const childClassName = child.props.className;
            const isVisible =
              index >= currentPage * numberItemsToShowCalculated &&
              index < (currentPage + 1) * numberItemsToShowCalculated;
            const width =
              numberItemsToShowCalculated === 1
                ? `w-full`
                : `w-1/${numberItemsToShowCalculated}`;
            return React.cloneElement(child, {
              className: twMerge(childClassName, width),
              isVisible,
            });
          })}
        </div>
      </div>
      {!isNextDisabled && (
        <SlideButton
          className="absolute right-0 bottom-1/2 transform translate-y-1/2"
          type={SlideButtonType.NEXT}
          onClick={next}
        />
      )}
    </div>
  );
};

export default Carousel;
