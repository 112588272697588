import { Builder, withChildren } from '@builder.io/react';
import React, { ReactElement } from 'react';

import BareBuilderSection from '../layouts/BareBuilderSection';

interface SiteLink {
  href: string;
  text: string;
}

interface SiteMapProps {
  children: ReactElement;
  heading: SiteLink;
}

const SiteMap = ({ children, heading }: SiteMapProps) => (
  <BareBuilderSection className="w-full">
    <>
      <div className="w-full bg-gray-700">
        <div className="max-w-7xl w-full p-4 mx-auto">
          <a className="text-3xl text-white font-bold" href={heading.href}>
            {heading.text}
          </a>
        </div>
      </div>
      <div className="w-full">{children}</div>
    </>
  </BareBuilderSection>
);

const SiteMapWithChildren = withChildren(SiteMap);

Builder.registerComponent(SiteMapWithChildren, {
  name: 'Site Map',

  childRequirements: {
    message: "You can only have 'Site Map Category' components as children",
    query: {
      'component.name': { $in: ['Site Map Category'] },
    },
  },

  inputs: [
    {
      name: 'heading',
      type: 'object',
      required: true,
      defaultValue: { href: '/', text: '[Heading]' },
      subFields: [
        {
          name: 'href',
          type: 'string',
          required: true,
          defaultValue: '/',
        },
        {
          name: 'text',
          type: 'string',
          required: true,
          defaultValue: '[Heading]',
        },
      ],
    },
  ],

  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Site Map Category',
        options: {
          heading: { href: '/', text: '[Category]' },
          siteLinks: [{ href: '/', text: '[Link]' }],
        },
      },
    },
  ],
});

export default SiteMap;
