import { Builder } from '@builder.io/react';
import React from 'react';

import { StaffPickTag as StorywindStaffPickTag } from '@videoblocks/storywind';

function StaffPickTag() {
  return <StorywindStaffPickTag />;
}

Builder.registerComponent(StaffPickTag, {
  name: 'Staff pick tag',
});
