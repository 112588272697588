import { Builder } from '@builder.io/react';
import React, { ReactElement } from 'react';

import CallToAction, {
  callToActionInputs,
  CallToActionProps,
} from '../../elements/CallToAction.builder';
import BuilderSection from '../../layouts/BuilderSection';

interface VimeoProps {
  paragraph?: string;
  videoId: string;
  darkMode: boolean;
  showCallToAction: boolean;
  callToAction?: CallToActionProps;
  imagesFirst: boolean;
  heading: string;
  subheading?: string;
  content: string;
  isPrimaryView: boolean;
}

function headerValue(subheading: string) {
  return subheading ? 'h3' : 'h2';
}

export const Vimeo = ({
  paragraph,
  videoId,
  darkMode,
  showCallToAction,
  callToAction,
  imagesFirst,
  heading,
  subheading,
  content,
  isPrimaryView,
}: VimeoProps): ReactElement => {
  const Title = headerValue(subheading);

  return (
    <BuilderSection
      bgClassNames={`${darkMode && 'bg-gray-950'}`}
      className={`${darkMode ? 'text-white' : 'text-gray-900'}`}
      headingClassNames="text-5xl font-serif text-center mb-8 w-full"
    >
      <>
        {isPrimaryView && (
          <div>
            <Title className="font-bold text-3xl md:text-5xl mb-6 font-serif text-center">
              {heading}
            </Title>
            <div className={darkMode ? 'text-white' : 'text-gray-900'}>
              {paragraph !== '<p><br></p>' && (
                <div
                  className="text-lg mb-8 mx-auto"
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                />
              )}
              <div className="max-w-4xl w-full px-8 justify-center mx-auto mb-8">
                <div className="aspect-w-16 aspect-h-9 w-full">
                  <iframe
                    width="560"
                    height="315"
                    src={`https://player.vimeo.com/video/${videoId}`}
                    title="Vimeo video player"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            <CallToAction
              {...callToAction}
              showCallToAction={showCallToAction}
            />
          </div>
        )}

        {!isPrimaryView && (
          <div className="flex justify-center flex-nowrap px-6 md:px-24 py-8 md:py-10 w-full flex flex-col md:flex-row items-center gap-8 justify-start">
            <div
              className={`${
                imagesFirst ? 'md:order-1' : 'md:order-2'
              } md:w-1/2 w-full`}
            >
              <div className="overflow-hidden">
                <div className="lg:block aspect-w-16 aspect-h-9 rounded-3xl">
                  <iframe
                    className="inset-0 object-cover rounded-2.5xl w-full h-full bg-gray-400"
                    width="560"
                    height="315"
                    src={`https://player.vimeo.com/video/${videoId}`}
                    title="Vimeo video player"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>

            <div
              className={`${
                imagesFirst ? 'md:order-2' : 'md:order-1'
              } md:w-1/2 w-full`}
            >
              {subheading && (
                <h2 className="font-semibold text-sm md:text-base uppercase mb-6 tracking-widest">
                  {subheading}
                </h2>
              )}
              <Title className="font-bold text-3xl md:text-5xl mb-6 font-serif">
                {heading}
              </Title>

              <div
                className={'font-normal text-base md:text-xl leading-7 mb-6'}
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
              <div className="text-center flex justify-center md:justify-start">
                <CallToAction
                  showCallToAction={showCallToAction}
                  {...callToAction}
                />
              </div>
            </div>
          </div>
        )}
      </>
    </BuilderSection>
  );
};

Builder.registerComponent(Vimeo, {
  name: 'Vimeo',
  inputs: [
    {
      name: 'isPrimaryView',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'paragraph',
      type: 'richText',
      defaultValue:
        'The demand for video is growing daily. Empower your teams to create faster than ever with unlimited stock downloads, iron-clad business licensing, and collaboration-first features to scale production easily and achieve your strategic goals.',
    },
    {
      name: 'videoId',
      type: 'string',
      required: true,
      defaultValue: '434170752',
    },
    { name: 'darkMode', type: 'boolean', defaultValue: false },
    {
      name: 'imagesFirst',
      type: 'boolean',
      required: true,
      defaultValue: false,
    },
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: '[Heading]',
    },
    {
      name: 'subheading',
      type: 'string',
      defaultValue: 'Create with storyblocks',
    },
    ...callToActionInputs,
  ],
});
