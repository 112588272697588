import { Builder } from '@builder.io/react';
import React from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import { lazyLoadDefaultConfig } from '../../constants';
import BuilderSection from '../layouts/BuilderSection';

interface SEOTextAndImageProps {
  content: string;
  heading: string;
  image: string;
  imageAlt: string;
  lazyLoadImage: boolean;
}

const SEOTextAndImage = ({
  content,
  heading,
  image,
  imageAlt,
  lazyLoadImage,
}: SEOTextAndImageProps) => {
  const imageSrc = `${__ASSETS_COMMON_IMAGES_URL__}${image}`;
  return (
    <BuilderSection className="flex justify-center">
      <div className="rounded-3xl flex-nowrap md:flex-row flex flex-col space-y-4 overflow-hidden bg-gray-900">
        <div className="aspect-w-16 aspect-h-9 md:w-1/3 md:aspect-none md:aspect-w-9 md:aspect-h-16 lg:aspect-none lg:aspect-w-1 lg:aspect-h-1">
          <ProgressiveImage
            className="object-cover w-full h-full"
            src={imageSrc}
            alt={imageAlt}
            shouldLazyLoad={lazyLoadImage}
          />
        </div>

        <div className="md:w-2/3 md:py-16 md:px-24 flex flex-col justify-center px-10 py-8 space-y-4">
          <h2 className="text-2xl font-bold text-left text-white">{heading}</h2>
          <div
            className="text-base text-left text-white"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(SEOTextAndImage, {
  name: 'SEO Text And Image',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Save time and money with stock footage',
    },
    {
      name: 'image',
      type: 'string',
      helperText: "Path to image asset, relative to '/assets/common/images'",
      regex: {
        pattern: '^/.*',
        options: '',
        message:
          "Make sure you include the leading slash and the path is relative to '/assets/common/images'",
      },
      required: true,
      defaultValue: '/marketing/diversity/article-preview-1.jpg', // Placeholder Image for Testing
    },
    {
      name: 'imageAlt',
      type: 'string',
      defaultValue: '',
    },
    {
      ...lazyLoadDefaultConfig,
    },
    {
      name: 'content',
      type: 'richText',
      defaultValue: `Using stock footage saves video producers time and money, and our library offers 
            a nearly endless supply of content. Whether you need space footage, videos of real people or 
            even green screen footage, we have what you need. Wrap any documentary, finish any YouTube video, 
            and nail any news story with high-quality HD and 4k stock video from Storyblocks.`,
      required: true,
    },
  ],
});
