import { Builder, withChildren } from '@builder.io/react';
import React from 'react';

import {
  Stack as StorywindStack,
  StackAlignment,
  StackDirection,
} from '@videoblocks/storywind';
import { StackProps } from '@videoblocks/storywind/components/Stack';

const StackWithChildren = withChildren(function Stack({
  direction,
  alignment,
  xGap,
  yGap,
  children,
}: StackProps) {
  return (
    <StorywindStack
      direction={direction}
      alignment={alignment}
      xGap={xGap}
      yGap={yGap}
    >
      {children}
    </StorywindStack>
  );
});

Builder.registerComponent(StackWithChildren, {
  name: 'Stack',
  inputs: [
    {
      name: 'direction',
      type: 'string',
      defaultValue: StackDirection.Column,
      enum: [
        {
          label: 'Column',
          value: StackDirection.Column,
        },
        {
          label: 'Row',
          value: StackDirection.Row,
        },
      ],
    },
    {
      name: 'alignment',
      type: 'string',
      defaultValue: StackAlignment.Left,
      enum: [
        {
          label: 'Left',
          value: StackAlignment.Left,
        },
        {
          label: 'Center',
          value: StackAlignment.Center,
        },
      ],
    },
    {
      name: 'xGap',
      type: 'number',
      required: false,
    },
    {
      name: 'yGap',
      type: 'number',
      required: false,
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Text goes here.' } },
    },
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Text goes here.' } },
    },
  ],
});
