import { Builder } from '@builder.io/react';
import { format } from 'date-fns';
import React from 'react';

interface FormattedDateProps {
  date: number;
  dateFormat?: string;
}

const FormattedDate: React.FC<FormattedDateProps> = ({
  date,
  dateFormat = 'MM/dd/yy',
}) => {
  if (!date) return null;

  return <>{format(new Date(date), dateFormat)}</>;
};

Builder.registerComponent(FormattedDate, {
  name: 'Formatted Date',
  inputs: [
    {
      name: 'date',
      type: 'string',
      required: true,
      defaultValue: new Date().toString(),
    },
    {
      name: 'dateFormat',
      type: 'string',
      required: false,
      defaultValue: 'MM/dd/yy',
      helperText:
        'Date format string (https://date-fns.org/v2.30.0/docs/format)',
    },
  ],
});
