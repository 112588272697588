import { Builder } from '@builder.io/react';
import React from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import CallToAction, {
  callToActionFields,
  CallToActionProps,
} from '../elements/CallToAction.builder';
import BuilderSection from '../layouts/BuilderSection';

interface PricingCardProps extends CallToActionProps {
  heading: string;
  description: string;
  icon: string;
}

interface PricingProps {
  heading: string;
  cards: PricingCardProps[];
}

const PricingCard = ({
  heading,
  description,
  icon,
  color,
  href,
  showCallToAction = true,
  size,
  squared,
  text,
  variant,
}: PricingCardProps) => (
  <div className="flex flex-col items-center justify-center w-56 h-full px-4 py-8 text-center border-solid border border-gray-400 rounded-lg">
    <ProgressiveImage
      className="h-12 w-auto"
      src={`${__ASSETS_COMMON_IMAGES_URL__}${icon}`}
      alt=""
      width="64"
      height="48"
      shouldLazyLoad
    />
    <p className="m-0 mt-4 text-xl font-bold">{heading}</p>
    <div
      className="m-0 mt-2 mb-4 text-sm leading-6 text-gray-900"
      dangerouslySetInnerHTML={{ __html: description }}
    />
    <CallToAction
      showCallToAction={showCallToAction}
      color={color}
      href={href}
      size={size}
      squared={squared}
      text={text}
      variant={variant}
    />
  </div>
);

const Pricing = ({ heading, cards }: PricingProps) => (
  <BuilderSection>
    <>
      <h3 className="block mb-6 font-bold text-gray-900 text-center font-serif text-5xl">
        {/* i.e. <BuilderSection /> heading, but h3 */}
        {heading}
      </h3>
      <div className="md:flex-row flex-nowrap flex flex-col items-center justify-center w-full gap-8">
        {cards?.length > 0 &&
          cards.map((cardProps: PricingCardProps, index) => (
            <PricingCard {...cardProps} key={`pricing-card-${index}`} />
          ))}
      </div>
    </>
  </BuilderSection>
);

Builder.registerComponent(Pricing, {
  name: 'Pricing',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Plans & Pricing',
    },
    {
      name: 'cards',
      type: 'list',
      required: true,
      defaultValue: [
        {
          heading: 'Individual',
          description:
            'Plans with licensing and features for content creators, freelancers and video professionals.',
          icon: '/icons/individual-v2.svg',
          text: 'See Plans',
          href: '/pricing',
        },
        {
          heading: 'Business',
          description:
            'Plans with enhanced licensing and exclusive features for broadcast, video production, and marketing teams.',
          icon: '/icons/business.svg',
          text: 'Learn More',
          href: '/pricing?type=ent',
          color: 'secondary',
          variant: 'outlined',
        },
      ],
      onChange: (options) => {
        if (options.get('cards').length > 4) {
          options.set('cards', options.get('cards').slice(0, 4));
          alert(
            'Maximum number of cards is 4. Modify an existing card instead.'
          );
        }
      },
      subFields: [
        {
          name: 'heading',
          type: 'string',
          required: true,
        },
        {
          name: 'description',
          type: 'richText',
          required: true,
        },
        {
          name: 'icon',
          type: 'string',
          required: true,
          helperText:
            "Path to image asset, relative to '/assets/common/images'",
          regex: {
            pattern: '^/.*',
            options: '',
            message:
              "Make sure you include the leading slash and the path is relative to '/assets/common/images'",
          },
        },
        ...callToActionFields,
      ],
    },
  ],
});
