import { BuilderBlocks, Builder } from '@builder.io/react';
import { tabsConfig } from '@builder.io/widgets/dist/lib/components/Tabs.config';
import React, { useState, useEffect, useMemo } from 'react';

// TabsComponent lifted from https://github.com/BuilderIO/builder/blob/main/packages/widgets/src/components/Tabs.tsx

type ElementType = any;

export interface TabsProps {
  tabs: {
    label: ElementType[];
    content: ElementType[];
  }[];
  builderBlock: { id: string };
  defaultActiveTab?: number;
  collapsible?: boolean;
  tabHeaderLayout?: string;
  activeTabStyle?: any;
}

export const TabsComponent = (props: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (props.defaultActiveTab) {
      setActiveTab(props.defaultActiveTab - 1);
    }
  }, []);

  const activeTabSpec = useMemo(
    () => props.tabs?.[activeTab],
    [activeTab, props.tabs]
  );

  return (
    <>
      <span
        className="builder-tabs-wrap flex flex-row overflow-auto"
        style={{
          justifyContent: props.tabHeaderLayout,
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {props.tabs?.map((item, index) => (
          <span
            tabIndex={0}
            role="button"
            key={index}
            className={
              'builder-tab-wrap ' +
              (activeTabSpec === item ? 'builder-tab-active' : '')
            }
            style={{
              ...((activeTabSpec === item && props.activeTabStyle) ||
                undefined),
            }}
            onKeyDown={() => {
              setActiveTab(
                index === activeTab && props.collapsible ? -1 : index
              );
            }}
            onClick={() => {
              setActiveTab(
                index === activeTab && props.collapsible ? -1 : index
              );
            }}
          >
            <BuilderBlocks
              parentElementId={props.builderBlock.id}
              dataPath={`component.options.tabs.${activeTab}.label`}
              blocks={item.label}
            />
          </span>
        ))}
      </span>

      {activeTabSpec && (
        <BuilderBlocks
          parentElementId={props.builderBlock.id}
          dataPath={`component.options.tabs.${activeTab}.content`}
          blocks={activeTabSpec?.content}
        />
      )}
    </>
  );
};

Builder.registerComponent(TabsComponent, tabsConfig);
