import { Builder } from '@builder.io/react';
import React from 'react';

import BuilderSection from '../layouts/BuilderSection';

interface TextHeroProps {
  content: string;
}

const TextHero = ({ content }: TextHeroProps) => {
  return (
    <BuilderSection className="flex flex-col justify-center">
      <div
        className="flex-center-self w-full text-center text-gray-900 font-serif text-3xl md:text-5xl"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </BuilderSection>
  );
};

Builder.registerComponent(TextHero, {
  name: 'Text Hero',
  inputs: [
    {
      name: 'content',
      type: 'richText',
      defaultValue: `Give your users complete creative freedom to tell any story with access to a massive, growing library of high-resolution stock video, audio, and images.`,
      helperText: '320 character limit recommended',
    },
  ],
});
