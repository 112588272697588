import { Builder } from '@builder.io/react';
import React from 'react';

import PillBuilder from '../../elements/Pill.builder';
import BuilderSection from '../../layouts/BuilderSection';

interface Pill {
  href: string;
  text: string;
}

interface PillsProps {
  heading: string;
  pills: Pill[];
  browseAllPill?: Pill;
}

const Pills = ({ heading, pills, browseAllPill }: PillsProps) => (
  <BuilderSection heading={heading}>
    <div className="gap-x-2 gap-y-4 flex flex-row flex-wrap items-center">
      {pills.map(({ href, text }, index) => (
        <PillBuilder key={`pill-${index}-${text}`} href={href} text={text} />
      ))}

      {browseAllPill && (
        <PillBuilder
          href={browseAllPill.href}
          text={browseAllPill.text}
          className="font-bold"
        />
      )}
    </div>
  </BuilderSection>
);

Builder.registerComponent(Pills, {
  name: 'Pills (Legacy)',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: '[Heading]',
    },
    {
      name: 'pills',
      type: 'list',
      required: true,
      defaultValue: [{ href: '/', text: 'Pill' }],
      subFields: [
        {
          name: 'href',
          type: 'string',
          required: true,
          defaultValue: '/',
        },
        {
          name: 'text',
          type: 'string',
          required: true,
          defaultValue: 'Pill',
        },
      ],
    },
    {
      name: 'browseAllPill',
      type: 'object',
      subFields: [
        {
          name: 'href',
          type: 'string',
          defaultValue: '/video/search',
        },
        {
          name: 'text',
          type: 'string',
          defaultValue: 'Browse All',
        },
      ],
    },
  ],
});

export default Pills;
