import { Builder } from '@builder.io/react';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import AudioContainer from '../../../Audio/containers/AudioContainer';
import SearchAPI from '../../../app/Search/SearchAPI';
import AudioStockItemCard from '../../../app/Search/components/card/AudioStockItemCard';
import {
  genres,
  legacyContentTypes,
  moods,
  musicInstruments,
  sfxCategories,
  vocalType as vocalTypes,
} from '../../../app/Search/entities/AudioSearchFilterOptions';
import AudioSearchOptions from '../../../app/Search/entities/AudioSearchOptions';
import { SiteEnum } from '../../../common/SiteConstants/SiteConstants';
import { audioSortOptions, defaultSortOptions } from '../../constants';
import headerAlignmentFontInputs, {
  Alignment,
  FontFamily,
} from '../../utils/headerAlignmentFontInputs';
import ExploreLink, {
  exploreLinkInputs,
  ExploreLinkProps,
} from '../elements/ExploreLink.builder';
import BuilderSection from '../layouts/BuilderSection';
import AudioStockItemCardLoader from '../loading-skeletons/AudioStockItemCardLoader';

const MAX_RESULTS_PER_PAGE = 9;

interface AudioCardListProps {
  heading: string;
  mediaType: string;
  musicGenres: Record<string, string>[];
  musicInstruments: Record<string, string>[];
  musicMoods: Record<string, string>[];
  numResults: number;
  numResultsMobile: number;
  searchTerm: string;
  sort: string;
  sfxCategories: Record<string, string>[];
  vocalType: string;
  showExploreLink: boolean;
  exploreLink?: ExploreLinkProps;
  headingAlignment: Alignment;
  fontFamily: FontFamily;
}

const AudioCardList = ({
  heading,
  mediaType,
  musicGenres,
  musicInstruments,
  musicMoods,
  numResults,
  numResultsMobile,
  searchTerm,
  sfxCategories,
  sort,
  vocalType,
  showExploreLink,
  exploreLink,
  headingAlignment = Alignment.Left,
  fontFamily = FontFamily.PolySans,
}: AudioCardListProps) => {
  const [stockItems, setStockItems] = useState([]);

  const resultsToFetch = Builder.isEditing
    ? MAX_RESULTS_PER_PAGE
    : Math.min(MAX_RESULTS_PER_PAGE, Math.max(numResults, numResultsMobile));

  const getSearchResults = () => {
    setStockItems([]);

    /** NOTE: object keys should match object keys in AudioMenuFilters.js */
    const searchParameters = new AudioSearchOptions().buildSearchApiParameters({
      contentType: mediaType,
      musicMoods: musicMoods?.map(({ mood }) => mood) || [],
      musicGenres: musicGenres?.map(({ genre }) => genre) || [],
      musicInstruments: musicInstruments.map(({ instrument }) => instrument),
      resultsPerPage: resultsToFetch,
      searchOrigin: Builder.isEditing
        ? 'builder_editing'
        : 'builder_audio_card_list',
      searchTerm,
      sfxCategories: sfxCategories?.map(({ sfxCategory }) => sfxCategory) || [],
      sort,
      vocalType,
    });

    SearchAPI.searchBuilder(SiteEnum.Audioblocks, searchParameters)
      .then((res) => res.json())
      .then((response) => {
        setStockItems(response?.data?.stockItems || []);
      })
      .catch(() => {
        // [MPB] TODO: determine how to handle search loading errors.
      });
  };

  const handleFetchSearchResultsRequest = useDebouncedCallback(
    getSearchResults,
    350,
    { maxWait: 1000 }
  );

  useEffect(() => {
    handleFetchSearchResultsRequest();
  }, [
    musicGenres,
    musicInstruments,
    mediaType,
    musicMoods,
    searchTerm,
    sfxCategories,
    sort,
    vocalType,
  ]);

  return (
    <BuilderSection
      className="site-audioblocks"
      heading={heading}
      headingClassNames={`${headingAlignment} ${fontFamily} text-5xl text-gray-900`}
    >
      <div className="w-full space-y-6">
        <AudioContainer />

        <div className="stock-item-group-wrapper card-design mx-0">
          {stockItems?.length < 1 && (
            <>
              <div className="flex flex-col mx-0">
                {[...Array(numResultsMobile)].map((_, index) => (
                  <AudioStockItemCardLoader
                    key={`audio-card-loader-${index}`}
                  />
                ))}
              </div>
              <div className="md:flex flex-col hidden w-full mx-0">
                {[...Array(Math.max(0, numResults - numResultsMobile))].map(
                  (_, index) => (
                    <AudioStockItemCardLoader
                      key={`audio-card-loader-${numResultsMobile + index}`}
                    />
                  )
                )}
              </div>
            </>
          )}

          {stockItems?.length > 0 && (
            <>
              <div className="flex flex-col mx-0">
                {stockItems.slice(0, numResultsMobile).map((item, index) => (
                  <AudioStockItemCard
                    {...item}
                    shouldShowAddToFavorites
                    stockItem={item.stockItem}
                    key={`audio-stock-item-card-${item.id || index}`}
                  />
                ))}
              </div>
              <div className="md:flex flex-col hidden w-full mx-0">
                {stockItems
                  .slice(numResultsMobile, numResults)
                  .map((item, index) => (
                    <AudioStockItemCard
                      {...item}
                      shouldShowAddToFavorites
                      stockItem={item.stockItem}
                      key={`audio-stock-item-card-${
                        item.id || numResultsMobile + index
                      }`}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
        <ExploreLink showExploreLink={showExploreLink} {...exploreLink} />
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(AudioCardList, {
  name: 'Audio Card List',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Browse Music',
    },
    {
      name: 'numResultsMobile',
      friendlyName: 'Number of Results (mobile)',
      type: 'number',
      defaultValue: 3,
      min: 3,
      max: MAX_RESULTS_PER_PAGE,
      step: 1,
      helperText:
        'Number of stock item cards to display on screen sizes < 672 pixels (i.e., mobile) (min = 3, max = 9)',
    },
    {
      name: 'numResults',
      friendlyName: 'Number of Results',
      type: 'number',
      defaultValue: 6,
      min: 3,
      max: MAX_RESULTS_PER_PAGE,
      step: 1,
      helperText:
        'Number of stock item cards to display on screen sizes >= 672 pixels (min = 3, max = 9)',
    },
    /* Audio Search Filters */
    {
      name: 'mediaType',
      type: 'string',
      required: true,
      defaultValue: 'all-audio',
      enum: legacyContentTypes,
      onChange: (options) => {
        // Clear all type-specific inputs
        options.set('musicMoods', []);
        options.set('musicGenres', []);
        options.set('musicInstruments', []);
        options.set('sfxCategories', []);
      },
    },
    {
      name: 'searchTerm',
      type: 'string',
      defaultValue: '',
    },
    {
      name: 'musicMoods',
      friendlyName: 'Moods',
      showIf: (options) => options.get('mediaType') === 'music',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'mood',
          type: 'string',
          enum: moods,
        },
      ],
    },
    {
      name: 'musicGenres',
      friendlyName: 'Genres',
      showIf: (options) => options.get('mediaType') === 'music',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'genre',
          type: 'string',
          enum: genres,
        },
      ],
    },
    {
      name: 'musicInstruments',
      friendlyName: 'Instruments',
      showIf: (options) => options.get('mediaType') === 'music',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'instrument',
          type: 'string',
          enum: musicInstruments,
        },
      ],
    },
    {
      name: 'vocalType',
      showIf: (options) => options.get('mediaType') === 'music',
      type: 'string',
      defaultValue: '',
      enum: vocalTypes,
    },
    {
      name: 'sfxCategories',
      friendlyName: 'Sound Effects Categories',
      showIf: (options) => options.get('mediaType') === 'sfx',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'sfxCategory',
          type: 'string',
          enum: sfxCategories,
        },
      ],
    },
    {
      name: 'sort',
      type: 'string',
      defaultValue: 'most_relevant',
      required: true,
      enum: [...defaultSortOptions, ...audioSortOptions],
    },
    ...headerAlignmentFontInputs,
    ...exploreLinkInputs,
  ],
});
