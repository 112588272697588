import React from 'react';
import { twMerge } from 'tailwind-merge';

import BareBuilderSection from './BareBuilderSection';

interface BuilderSectionProps {
  bgClassNames?: string;
  className?: string;
  heading?: string;
  headingClassNames?: string;
  subheading?: string;
}

const BuilderSection: React.FC<BuilderSectionProps> = ({
  bgClassNames = '',
  className = '',
  children = null,
  heading = '',
  headingClassNames = 'text-gray-900 text-center lg:text-left font-sans text-2xl',
  subheading = '',
}) => (
  <BareBuilderSection
    bgClassNames={bgClassNames}
    className={twMerge(
      'max-w-7xl md:px-6 lg:py-8 md:py-6 w-full px-4 py-5 mx-auto flex flex-col',
      className
    )}
  >
    {heading && (
      <h2 className={twMerge('block mb-6 font-bold', headingClassNames)}>
        {heading}
      </h2>
    )}

    {subheading && (
      <div
        className="mb-6 text-base font-normal"
        dangerouslySetInnerHTML={{ __html: subheading }}
      ></div>
    )}

    {children}
  </BareBuilderSection>
);

export default BuilderSection;
