import { Builder } from '@builder.io/react';
import React from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import BuilderSection from '../../layouts/BuilderSection';

interface BenefitProps {
  heading?: string;
  icon?: string;
  text?: string;
}

interface BenefitsV1Props {
  heading: string;
  benefits: Record<string, string>[];
}

const Benefit = ({ heading, icon, text }: BenefitProps) => (
  <div className="rounded-xl flex flex-col items-center w-full max-w-xs p-6 font-sans text-center shadow-lg">
    <ProgressiveImage
      src={`${__ASSETS_COMMON_IMAGES_URL__}${icon}`}
      className="w-12 h-12"
      width="48"
      height="48"
      shouldLazyLoad
    />

    <h3 className="text-lg font-bold leading-6">{heading}</h3>

    <div
      className="text-sm leading-6 text-gray-900"
      dangerouslySetInnerHTML={{ __html: text }}
    ></div>
  </div>
);

const BenefitsV1 = ({ heading, benefits }: BenefitsV1Props) => (
  <BuilderSection heading={heading}>
    <div className="md:grid-cols-2 lg:grid-cols-4 justify-items-center grid grid-cols-1 gap-8">
      {benefits?.length > 0 &&
        benefits.map((benefit, index) => (
          <Benefit key={`benefitv1-${index}`} {...benefit} />
        ))}
    </div>
  </BuilderSection>
);

Builder.registerComponent(BenefitsV1, {
  name: 'Benefits (v1)',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Why Storyblocks?',
    },
    {
      name: 'benefits',
      type: 'list',
      required: true,
      defaultValue: [
        {
          heading: 'Unbeatable Savings',
          text: 'Our subscription model gives you way more content for your money. We offer a flexible plan for every budget.',
          icon: '/media-page/redesigned-footage/bag.svg',
        },
        {
          heading: 'Royalty-Free',
          text: 'All of our royalty free audio content can be used multiple times for commercial and personal projects.',
          icon: '/media-page/redesigned-footage/ribbon.svg',
        },
        {
          heading: 'Fresh Content',
          text: 'We’re always adding new content based on what you’re searching for.',
          icon: '/media-page/redesigned-footage/star.svg',
        },
        {
          heading: 'Yours Forever',
          text: 'Anything you download is yours to keep and use forever.',
          icon: '/media-page/redesigned-footage/bookmark.svg',
        },
      ],
      onChange: (options) => {
        if (options.get('benefits').length > 4) {
          options.set('benefits', options.get('benefits').slice(0, 4));
          alert(
            'Maximum number of benefits is 4. Modify an existing benefit instead.'
          );
        }
      },
      subFields: [
        {
          name: 'heading',
          type: 'string',
          required: true,
        },
        {
          name: 'text',
          type: 'richText',
          required: true,
        },
        {
          name: 'icon',
          type: 'string',
          required: true,
          helperText:
            "Path to image asset, relative to '/assets/common/images'",
          regex: {
            pattern: '^/.*',
            options: '',
            message:
              "Make sure you include the leading slash and the path is relative to '/assets/common/images'",
          },
        },
      ],
    },
  ],
});
