import { Builder } from '@builder.io/react';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import KeyboardArrowRightIcon from '@videoblocks/react-icons/KeyboardArrowRight';

import SearchAPI from '../../../../app/Search/SearchAPI';
import VideoStockItemCard from '../../../../app/Search/components/card/VideoStockItemCard';
import {
  frameRates as frameRateOptions,
  getCategoriesForContentType,
  contentTypes,
  qualities as videoResolutionOptions,
} from '../../../../app/Search/entities/VideoSearchFilterOptions';
import VideoSearchOptions from '../../../../app/Search/entities/VideoSearchOptions';
import { SiteEnum } from '../../../../common/SiteConstants/SiteConstants';
import {
  MAX_RESULTS_PER_PAGE,
  defaultSortOptions,
  stockItemCardResultOptions,
  videoSortOptions,
} from '../../../constants';
import BuilderSection from '../../layouts/BuilderSection';
import StockItemCardLoader from '../../loading-skeletons/StockItemCardLoader';

interface VideoContentGridProps {
  customContent?: any;
  category: string;
  frameRates: string[];
  heading: string;
  exploreLink?: string;
  exploreText?: string;
  mediaType: string;
  minQualityScore?: number;
  numResultsMobile: string;
  numResults: string;
  resolution: string;
  searchTerm: string;
  sort: string;
}

const VideoContentGrid = ({
  customContent,
  category,
  frameRates,
  heading,
  exploreLink,
  exploreText,
  mediaType,
  minQualityScore,
  numResultsMobile,
  numResults,
  resolution,
  searchTerm,
  sort,
}: VideoContentGridProps) => {
  const [content, setContent] = useState(customContent || []);

  const mobileNumResults = parseInt(numResultsMobile, 10);
  const desktopNumResults = parseInt(numResults, 10);

  const resultsToFetch = Builder.isEditing
    ? MAX_RESULTS_PER_PAGE
    : Math.min(
        MAX_RESULTS_PER_PAGE,
        Math.max(desktopNumResults, mobileNumResults)
      );

  const getSearchResults = () => {
    setContent([]);

    /** NOTE: object keys should match object keys in VideoMenuFilters.js */
    const searchParameters = new VideoSearchOptions().buildSearchApiParameters({
      categories: category,
      contentType: mediaType,
      frameRates,
      resultsPerPage: resultsToFetch,
      searchOrigin: Builder.isEditing
        ? 'builder_editing'
        : 'builder-video-content-grid',
      searchTerm,
      sort,
      minQualityScore,
      videoQuality: resolution,
    });

    SearchAPI.searchBuilder(SiteEnum.Videoblocks, searchParameters)
      .then((resp) => resp.json())
      .then((resp) => {
        setContent(resp?.data?.stockItems || []);
      })
      .catch(() => {
        // [MPB] TODO: determine how to handle search loading errors.
      });
  };

  const handleFetchSearchResultsRequest = useDebouncedCallback(
    getSearchResults,
    350,
    { maxWait: 1000 }
  );

  useEffect(() => {
    if (Builder.isEditing || !customContent) {
      handleFetchSearchResultsRequest();
    }
  }, [
    category,
    frameRates,
    mediaType,
    resolution,
    searchTerm,
    sort,
    minQualityScore,
  ]);

  return (
    <BuilderSection
      heading={heading}
      headingClassNames="text-gray-900 text-left font-sans text-2xl"
    >
      <div className="w-full space-y-6">
        <div className="stock-item-group-wrapper card-design leading">
          {content?.length < 1 ? (
            <div className="grid grid-cols-1 gap-4">
              <div className="md:grid-cols-3 grid grid-cols-1 gap-4">
                {[...Array(mobileNumResults)].map((_, index) => (
                  <StockItemCardLoader key={`video-card-loader-${index}`} />
                ))}
              </div>
              <div className="md:grid md:grid-cols-3 md:gap-4 hidden">
                {[
                  ...Array(Math.max(0, desktopNumResults - mobileNumResults)),
                ].map((_, index) => (
                  <StockItemCardLoader
                    key={`video-card-loader-${numResultsMobile + index}`}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="stock-item-v2 grid grid-cols-1 gap-4">
              <div className="md:grid-cols-3 grid grid-cols-1 gap-4">
                {content.slice(0, mobileNumResults).map((item, index) => (
                  <VideoStockItemCard
                    {...item}
                    classNames={['m-0', 'p-0']}
                    containerResponsiveClassNames={[]}
                    isCollapsedStockItemLoadMoreCard={false}
                    shouldInvertControlsVisibility={true}
                    stockItem={item.stockItem}
                    thumbnailClassNames="overflow-hidden rounded-lg"
                    key={`video-stock-item-card-${item.id || index}`}
                  />
                ))}
              </div>
              <div className="md:grid md:grid-cols-3 md:gap-4 hidden">
                {content
                  .slice(mobileNumResults, desktopNumResults)
                  .map((item, index) => (
                    <VideoStockItemCard
                      {...item}
                      classNames={['m-0', 'p-0']}
                      containerResponsiveClassNames={[]}
                      isCollapsedStockItemLoadMoreCard={false}
                      shouldInvertControlsVisibility={true}
                      stockItem={item.stockItem}
                      thumbnailClassNames="overflow-hidden rounded-lg"
                      key={`video-stock-item-card-${
                        item.id || mobileNumResults + index
                      }`}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>

        {exploreText?.length && (
          <a
            href={exploreLink}
            className="flex items-center float-right gap-1 text-base font-bold text-gray-700 cursor-pointer"
          >
            {exploreText}
            <KeyboardArrowRightIcon className="fill-current w-3.5" />
          </a>
        )}
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(VideoContentGrid, {
  name: 'Video Content Grid (Legacy)',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Browse Videos',
    },
    {
      name: 'numResultsMobile',
      friendlyName: 'Number of Results (mobile)',
      type: 'string',
      enum: stockItemCardResultOptions,
      defaultValue: '3',
      required: true,
    },
    {
      name: 'numResults',
      friendlyName: 'Number of Results',
      type: 'string',
      enum: stockItemCardResultOptions,
      defaultValue: '9',
      required: true,
    },
    {
      name: 'exploreText',
      type: 'string',
      required: true,
      defaultValue: 'Explore all content',
    },
    {
      name: 'exploreLink',
      type: 'string',
      defaultValue: '',
      showIf: (options) => options.get('exploreText'),
    },
    {
      name: 'mediaType',
      type: 'string',
      required: true,
      enum: contentTypes,
      defaultValue: 'all-videos',
    },
    {
      name: 'searchTerm',
      type: 'string',
      defaultValue: '',
    },
    {
      name: 'resolution',
      type: 'string',
      enum: videoResolutionOptions,
      defaultValue: '',
    },
    {
      name: 'frameRate',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'rate',
          type: 'string',
          enum: frameRateOptions,
        },
      ],
    },
    {
      name: 'category',
      type: 'string',
      showIf: (options) => options.get('mediaType') === 'all-videos',
      enum: getCategoriesForContentType('all-videos'),
      defaultValue: '',
    },
    {
      name: 'category',
      type: 'string',
      showIf: (options) => options.get('mediaType') === 'footage',
      enum: getCategoriesForContentType('footage'),
      defaultValue: '',
    },
    {
      name: 'category',
      type: 'string',
      showIf: (options) => options.get('mediaType') === 'motion-backgrounds',
      enum: getCategoriesForContentType('motion-backgrounds'),
      defaultValue: '',
    },
    {
      name: 'category',
      type: 'string',
      showIf: (options) => options.get('mediaType') === 'templates',
      enum: getCategoriesForContentType('templates'),
      defaultValue: '',
    },
    {
      name: 'sort',
      type: 'string',
      defaultValue: 'most_relevant',
      required: true,
      enum: [...defaultSortOptions, ...videoSortOptions],
    },
    {
      name: 'minQualityScore',
      type: 'number',
      defaultValue: null,
      min: 0,
      max: 1,
    },
  ],
});
