import React from 'react';
import { twMerge } from 'tailwind-merge';

import { ProgressiveImage } from '@videoblocks/storywind';

import { ContentClass } from './enums';
import { urlSegmentForContentClass } from './utils/utils';

export interface CollectionCardProps {
  className?: string;
  label: string;
  labelClassName?: string;
  href: string;
  thumbnailSrc: string;
  thumbnailAlt: string;
  thumbnailClassName?: string;
  showNewLabel?: boolean;
  count?: number;
  onClick?: () => void;
  iconContentClasses?: ContentClass[];
  collectionLinkContentClass?: ContentClass;
  newLabelClassName?: string;
  imagesUrl: string;
}

const assetIconsPath = {
  video: 'collections/collection-icon-video.svg',
  audio: 'collections/collection-icon-audio.svg',
  image: 'collections/collection-icon-image.svg',
};

const CollectionCard = ({
  className = '',
  label = '',
  labelClassName = '',
  href = '',
  thumbnailSrc = '',
  thumbnailAlt = '',
  thumbnailClassName = '',
  showNewLabel = false,
  count,
  onClick = () => null,
  iconContentClasses = [],
  collectionLinkContentClass = ContentClass.Video,
  newLabelClassName = '',
  imagesUrl = '',
}: CollectionCardProps) => {
  return (
    <div className="flex flex-col w-full space-y-6">
      <a
        className={twMerge(
          'group aspect-w-4 aspect-h-3 relative overflow-hidden rounded-lg safari-rounded-border cursor-pointer border-solid border-1 border-transparent focus:border-blue-500',
          className
        )}
        href={`/${urlSegmentForContentClass(
          collectionLinkContentClass
        )}/collections/${href}`}
        aria-label={label}
        aria-hidden={false}
        onClick={onClick}
      >
        <ProgressiveImage
          className={`object-cover w-full h-full transform group-hover:scale-110 transition duration-300 ${thumbnailClassName}`}
          src={thumbnailSrc}
          shouldLazyLoad
          alt={thumbnailAlt}
        />

        {/* Opaque dark gray box gradient to make text stand out */}
        <div className="bg-gradient-to-t from-gray-900 to-transparent via-transparent absolute inset-0 w-full h-full" />

        {showNewLabel && (
          <div
            className={twMerge(
              'top-4 left-4 w-max absolute flex items-center justify-center h-6 px-2 text-sm font-bold text-black no-underline bg-white rounded-sm',
              newLabelClassName
            )}
          >
            New
          </div>
        )}
        <div
          className={`top-4 right-4 w-full absolute px-4 text-right font-medium m-0 text-base text-white no-underline ${labelClassName}`}
        >
          {count && <span className="text-shadow-1">{count}</span>}
          {iconContentClasses?.length === 0 && (
            <ProgressiveImage
              className="ml-2"
              src={`${imagesUrl}/collections/collection-icon-agnostic.svg`}
              shouldLazyLoad
              alt={''}
              height={22}
              width={22}
            />
          )}
        </div>
        <div>
          <div className="absolute bottom-0 left-0 flex flex-col">
            <div className="flex spacing-x-2">
              {iconContentClasses?.length > 0 &&
                iconContentClasses.map((contentClass, index) => {
                  const path = assetIconsPath[contentClass];
                  return (
                    <ProgressiveImage
                      key={index}
                      className="ml-2"
                      src={`${imagesUrl}/${path}`}
                      shouldLazyLoad
                      alt=""
                      height={22}
                      width={22}
                    />
                  );
                })}
            </div>
            <h2
              className={twMerge(
                'flex items-end p-4 m-0 text-xl text-white no-underline whitespace-pre-wrap',
                labelClassName
              )}
            >
              {label}
            </h2>
          </div>
        </div>
      </a>
    </div>
  );
};

export default CollectionCard;
