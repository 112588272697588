import { Builder } from '@builder.io/react';
import React from 'react';

import {
  LogoColor,
  StoryblocksLogo as StorywindStoryblocksLogo,
} from '@videoblocks/storywind';

type StoryblocksLogoPropsType = {
  color: LogoColor;
};

export default function StoryblocksLogo({ color }: StoryblocksLogoPropsType) {
  return <StorywindStoryblocksLogo color={color} />;
}

Builder.registerComponent(StoryblocksLogo, {
  name: 'Storyblocks logo',
  inputs: [
    {
      name: 'color',
      type: 'string',
      defaultValue: LogoColor.Light,
      enum: [
        {
          label: 'Light',
          value: LogoColor.Light,
        },
        {
          label: 'Dark',
          value: LogoColor.Dark,
        },
        {
          label: 'Transparent Light',
          value: LogoColor.TransparentLight,
        },
        {
          label: 'Transparent Dark',
          value: LogoColor.TransparentDark,
        },
      ],
    },
  ],
});
