import { Builder } from '@builder.io/react';
import React from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import { lazyLoadDefaultConfig } from '../../constants';
import BuilderSection from '../layouts/BuilderSection';

interface Testimonial {
  name: string;
  quote: string;
  avatar: string;
  href: string;
  lazyLoadImage: boolean;
}

interface TestimonialsProps {
  heading: string;
  testimonials: Testimonial[];
}

const Testimonial = ({ name, quote, avatar, href, lazyLoadImage }) => (
  <a
    href={href}
    aria-label={`Check out ${name}'s work`}
    className="group flex flex-col items-center w-full max-w-sm p-8 space-y-4 no-underline bg-white rounded-lg"
  >
    <div
      className="my-8 text-base font-normal leading-6 text-gray-800 italic"
      dangerouslySetInnerHTML={{ __html: quote }}
    />
    <div className="flex flex-row justify-center lg:justify-start">
      <div className="w-14 h-14 overflow-hidden rounded-full">
        <ProgressiveImage
          className="group-hover:scale-110 object-cover w-full h-full transition duration-300 transform"
          src={`${__ASSETS_COMMON_IMAGES_URL__}${avatar}`}
          alt={`Picture of ${name}`}
          shouldLazyLoad={lazyLoadImage}
        />
      </div>
      <h3 className="ml-4 my-auto text-sm font-semibold text-center uppercase tracking-widest text-black">
        {name}
      </h3>
    </div>
  </a>
);

const Testimonials = ({ heading, testimonials }: TestimonialsProps) => (
  <BuilderSection
    heading={heading}
    headingClassNames="w-full md:w-1/2 mx-auto text-center text-gray-900 text-3xl lg:text-5xl font-serif"
  >
    <div className="justify-items-center md:grid-cols-3 grid w-full grid-cols-1 gap-8">
      {testimonials?.length > 0 &&
        testimonials.map((testimonial, index) => (
          <Testimonial key={`testimonial-${index}`} {...testimonial} />
        ))}
    </div>
  </BuilderSection>
);

Builder.registerComponent(Testimonials, {
  name: 'Testimonials',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Trusted by production companies across the world',
    },
    {
      name: 'testimonials',
      type: 'list',
      required: true,
      defaultValue: [
        {
          name: 'title, company name',
          quote:
            '“I love having instant access to these amazing clips to use in my video projects! Knowning I have unlimited downloaded - it lets me test out different clip types to use for my projects!”',
          avatar: '/youtube/bryan-francisco.jpg',
          lazyLoadImage: true,
        },
        {
          name: 'title, company name',
          quote:
            '“I love having instant access to these amazing clips to use in my video projects! Knowning I have unlimited downloaded - it lets me test out different clip types to use for my projects!”',
          avatar: '/youtube/cody-wanner.jpg',
          lazyLoadImage: true,
        },
        {
          name: 'title, company name',
          quote:
            '“I love having instant access to these amazing clips to use in my video projects! Knowning I have unlimited downloaded - it lets me test out different clip types to use for my projects!”',
          avatar: '/youtube/currently-hannah.jpg',
          lazyLoadImage: true,
        },
      ],
      onChange: (options) => {
        if (options.get('testimonials').length > 3) {
          options.set('testimonials', options.get('testimonials').slice(0, 3));
          alert(
            'Maximum number of testimonials is 3. Modify an existing testimonial instead.'
          );
        }
      },
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
          defaultValue: '[Name]',
        },
        {
          name: 'quote',
          type: 'richText',
          required: true,
          defaultValue: '[Quote]',
        },
        {
          name: 'avatar',
          type: 'string',
          required: true,
          defaultValue: '/icons/individual.svg',
        },
        {
          name: 'href',
          type: 'string',
          required: true,
          defaultValue: '/',
        },
        {
          ...lazyLoadDefaultConfig,
        },
      ],
    },
  ],
});
