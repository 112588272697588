import React from 'react';
import { twMerge } from 'tailwind-merge';

interface BareBuilderSectionProps {
  bgClassNames?: string;
  className?: string;
}

const BareBuilderSection: React.FC<BareBuilderSectionProps> = ({
  bgClassNames = '',
  className = '',
  children = null,
}) => (
  <section className={twMerge('w-full', bgClassNames)}>
    <div className={className}>{children}</div>
  </section>
);

export default BareBuilderSection;
