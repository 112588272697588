import { Builder } from '@builder.io/react';
import React from 'react';

import ImageCardBuilder from '../elements/ImageCard.builder';
import BuilderSection from '../layouts/BuilderSection';
import FiveColumnGrid from '../layouts/FiveColumnGrid.builder';

interface Mood {
  label: string;
  href: string;
  imageSrc: string;
  showNewLabel: boolean;
}

interface MoodsProps {
  heading: string;
  moods: Mood[];
}

const Moods = ({ heading, moods }: MoodsProps) => (
  <BuilderSection heading={heading} className="w-full">
    <div className="flex flex-col w-full space-y-8">
      <FiveColumnGrid>
        {moods?.length > 0 &&
          moods.map(({ label, href, imageSrc, showNewLabel }, index) => (
            <ImageCardBuilder
              key={`${label}-${index}`}
              className="md:aspect-w-16 md:aspect-h-9 aspect-w-1 aspect-h-1"
              label={label}
              href={href}
              imageSrc={imageSrc}
              imageAlt={label}
              showNewLabel={showNewLabel}
            />
          ))}
      </FiveColumnGrid>
    </div>
  </BuilderSection>
);

Builder.registerComponent(Moods, {
  name: 'Moods',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Moods',
    },
    {
      name: 'moods',
      type: 'list',
      required: true,
      helperText: 'A list of moods that links to the search page',
      defaultValue: [
        {
          label: 'Happy',
          href: '/audio/search?media-type=music&categories=happy',
          imageSrc: '/browse/music/happy.jpg',
          showNewLabel: false,
        },
        {
          label: 'Dark',
          href: '/audio/search?media-type=music&categories=dark',
          imageSrc: '/browse/music/dark.jpg',
          showNewLabel: false,
        },
        {
          label: 'Love',
          href: '/audio/search?media-type=music&categories=love',
          imageSrc: '/browse/music/love.jpg',
          showNewLabel: false,
        },
        {
          label: 'Relaxing',
          href: '/audio/search?media-type=music&categories=relaxing',
          imageSrc: '/browse/music/relaxing.jpg',
          showNewLabel: false,
        },
        {
          label: 'Playful',
          href: '/audio/search?media-type=music&categories=playful',
          imageSrc: '/browse/music/playful.jpg',
          showNewLabel: false,
        },
        {
          label: 'Angry',
          href: '/audio/search?media-type=music&categories=angry',
          imageSrc: '/browse/music/angry.jpg',
          showNewLabel: false,
        },
        {
          label: 'Inspiring',
          href: '/audio/search?media-type=music&categories=inspiring',
          imageSrc: '/browse/music/inspiring.jpg',
          showNewLabel: false,
        },
        {
          label: 'Sad',
          href: '/audio/search?media-type=music&categories=sad',
          imageSrc: '/browse/music/sad.jpg',
          showNewLabel: false,
        },
        {
          label: 'Epic',
          href: '/audio/search?media-type=music&categories=epic',
          imageSrc: '/browse/music/epic.jpg',
          showNewLabel: false,
        },
        {
          label: 'Serious',
          href: '/audio/search?media-type=music&categories=serious',
          imageSrc: '/browse/music/serious.jpg',
          showNewLabel: false,
        },
      ],
      subFields: [
        {
          name: 'label',
          type: 'string',
          required: true,
        },
        {
          name: 'href',
          type: 'string',
          required: true,
        },
        {
          friendlyName: 'Image Source',
          name: 'imageSrc',
          type: 'string',
          required: true,
          helperText:
            "Path to image asset, relative to '/assets/common/images'",
          regex: {
            pattern: '^/.*',
            options: '',
            message:
              "Make sure you include the leading slash and the path is relative to '/assets/common/images'",
          },
        },
        {
          name: 'showNewLabel',
          type: 'boolean',
          required: true,
          defaultValue: false,
        },
      ],
    },
  ],
});
