import React, { ReactElement } from 'react';

interface PillBuilderProps {
  href: string;
  text: string;
  className?: string;
}

const PillBuilder = ({
  href = '',
  text = '',
  className = '',
}: PillBuilderProps): ReactElement => (
  <a
    className={`hover:bg-white hover:border-black duration-250 px-6 py-2 text-base text-black no-underline transition-all bg-gray-200 border border-transparent border-solid rounded-full ${className}`}
    href={href}
  >
    {text}
  </a>
);

export default PillBuilder;
