import { Builder } from '@builder.io/react';
import React from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import { FontFamily } from '../../utils/headerAlignmentFontInputs';
import BuilderSection from '../layouts/BuilderSection';

interface Feature {
  feature: string;
  link: string;
}

interface AssetProps {
  darkMode: boolean;
  fontFamily: FontFamily;
  numbers: boolean;
  count: string;
  featureList: Feature[];
  icon: string;
}

interface AssetsCounterProps {
  darkMode: boolean;
  numbers: boolean;
  heading: string;
  subheading: string;
  fontFamily: FontFamily;
  videoFeaturesIcon: string;
  videoFeatures: Feature[];
  audioFeaturesIcon: string;
  audioFeatures: Feature[];
  imageFeaturesIcon: string;
  imageFeatures: Feature[];
}

const AssetList = ({
  darkMode,
  numbers,
  fontFamily,
  count,
  featureList,
  icon,
}: AssetProps) => (
  <div
    className={`flex flex-col text-center md:text-left ${
      darkMode ? 'text-white' : 'text-gray-900'
    }`}
  >
    <div className="mb-6">
      <ProgressiveImage
        alt=""
        className="fill-current text-white"
        src={`${__ASSETS_COMMON_IMAGES_URL__}${icon}`}
        width="34"
        height="34"
        shouldLazyLoad
      />
    </div>

    {numbers && count && (
      <h4 className={`mt-0 mb-6 text-3xl font-bold ${fontFamily}`}>{count}+</h4>
    )}
    <ul className="list-none">
      {featureList.map(({ feature, link }, index) => (
        <li key={`asset-${index}-${feature}`}>
          <a
            className={`hover:underline text-base font-normal cursor-pointer ${
              darkMode ? 'text-white' : 'text-gray-900'
            }`}
            href={link}
          >
            {feature}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

const AssetsCounter = ({
  darkMode,
  numbers,
  heading,
  subheading,
  fontFamily,
  videoFeaturesIcon,
  videoFeatures,
  audioFeaturesIcon,
  audioFeatures,
  imageFeaturesIcon,
  imageFeatures,
}: AssetsCounterProps) => {
  const videoCount = __INITIAL_STATE__?.builder?.assetsCounter?.videoCount;
  const audioCount = __INITIAL_STATE__?.builder?.assetsCounter?.audioCount;
  const imageCount = __INITIAL_STATE__?.builder?.assetsCounter?.imageCount;

  const videoAssetCounter = {
    featureList: videoFeatures,
    count: videoCount !== '' ? videoCount : '1,407,000',
    icon: `/icons/${videoFeaturesIcon}_${darkMode ? 'white' : 'black'}.svg`,
  };

  const audioAssetCounter = {
    featureList: audioFeatures,
    count: audioCount !== '' ? audioCount : '74,000',
    icon: `/icons/${audioFeaturesIcon}_${darkMode ? 'white' : 'black'}.svg`,
  };

  const imageAssetCounter = {
    featureList: imageFeatures,
    count: imageCount !== '' ? imageCount : '433,000',
    icon: `/icons/${imageFeaturesIcon}_${darkMode ? 'white' : 'black'}.svg`,
  };

  return (
    <BuilderSection
      className="flex justify-center"
      bgClassNames={`${darkMode ? 'bg-gray-950 text-white' : 'text-gray-900'}`}
    >
      <div className="flex flex-col">
        <h2 className="mt-12 mb-4 text-center text-base font-normal uppercase tracking-widest">
          {subheading}
        </h2>
        <h3
          className={`mt-0 mb-12 w-full md:w-3/4 text-3xl md:text-5xl leading-12 md:leading-16 font-bold text-center mx-4 flex-center-self ${fontFamily}`}
        >
          {heading}
        </h3>
        <div className="flex flex-col md:flex-row justify-center gap-32 mb-7">
          {[videoAssetCounter, audioAssetCounter, imageAssetCounter].map(
            (asset, index) => (
              <AssetList
                darkMode={darkMode}
                numbers={numbers}
                key={`asset-list-${index}`}
                fontFamily={fontFamily}
                {...asset}
              />
            )
          )}
        </div>
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(AssetsCounter, {
  name: 'Assets Counter',
  inputs: [
    {
      name: 'darkMode',
      type: 'boolean',
      required: true,
      defaultValue: false,
    },
    {
      name: 'numbers',
      type: 'boolean',
      required: true,
      defaultValue: true,
    },
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue:
        'Unlimited access to a constantly growing library at your fingertips',
    },
    {
      name: 'subheading',
      type: 'string',
      required: true,
      defaultValue: 'unlimited library',
    },
    {
      name: 'fontFamily',
      type: 'string',
      defaultValue: FontFamily.PolySans,
      required: true,
      enum: [
        {
          label: 'Serif',
          value: FontFamily.PolySans,
        },
        {
          label: 'Sans',
          value: FontFamily.Inter,
        },
      ],
    },
    {
      name: 'videoFeaturesIcon',
      type: 'string',
      required: true,
      defaultValue: 'video_icon',
      helperText:
        "text '_black.svg' or '_white.svg' will be appended automatically at the end of icon source for dark/white mode",
    },
    {
      name: 'audioFeaturesIcon',
      type: 'string',
      required: true,
      defaultValue: 'music_icon',
      helperText:
        "text '_black.svg' or '_white.svg' will be appended automatically at the end of icon source for dark/white mode",
    },
    {
      name: 'imageFeaturesIcon',
      type: 'string',
      required: true,
      defaultValue: 'image_icon',
      helperText:
        "text '_black.svg' or '_white.svg' will be appended automatically at the end of icon source for dark/white mode",
    },
    {
      name: 'videoFeatures',
      type: 'list',
      required: true,
      defaultValue: [
        { feature: 'HD and 4K Footage', link: '/video/footage' },
        {
          feature: 'After Effects Templates',
          link: '/video/after-effects/templates',
        },
        { feature: 'Motion Backgrounds', link: '/video/motion-backgrounds' },
      ],
      subFields: [
        {
          name: 'feature',
          type: 'string',
          required: true,
        },
        {
          name: 'link',
          type: 'string',
          required: true,
        },
      ],
    },
    {
      name: 'audioFeatures',
      type: 'list',
      required: true,
      defaultValue: [
        { feature: 'Music Tracks', link: '/audio/music' },
        { feature: 'Sound Effects', link: '/audio/sound-effects' },
      ],
      subFields: [
        {
          name: 'feature',
          type: 'string',
          required: true,
        },
        {
          name: 'link',
          type: 'string',
          required: true,
        },
      ],
    },
    {
      name: 'imageFeatures',
      type: 'list',
      required: true,
      defaultValue: [
        { feature: 'Photos', link: '/images/photos' },
        { feature: 'Vectors', link: '/images/vectors' },
        { feature: 'Illustrations', link: '/images/illustrations' },
      ],
      subFields: [
        {
          name: 'feature',
          type: 'string',
          required: true,
        },
        {
          name: 'link',
          type: 'string',
          required: true,
        },
      ],
    },
  ],
});
