import { Builder } from '@builder.io/react';
import React from 'react';

import BuilderSection from '../layouts/BuilderSection';

interface SEOTextProps {
  heading: string;
  content: string;
}

const SEOText = ({ content, heading }: SEOTextProps) => {
  return (
    <BuilderSection className="flex justify-center" heading={''}>
      <div className="rounded-3xl flex-nowrap md:flex-row flex flex-col space-y-4 overflow-hidden bg-gray-900">
        <div className="md:w-1/3 flex flex-col justify-center px-10 md:px-0 md:pl-24 pt-8 md:py-8 space-y-4">
          <h2
            className={`text-3xl md:text-5xl font-bold text-left text-white m-0 font-serif`}
          >
            {heading}
          </h2>
        </div>
        <div className="md:w-2/3 md:py-16 md:px-24 flex flex-col justify-center px-10 py-8 space-y-4">
          <div
            className="text-base text-left text-white"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(SEOText, {
  name: 'SEO Text',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Save time and money with stock footage',
    },
    {
      name: 'content',
      type: 'richText',
      required: true,
      defaultValue: `Using stock footage saves video producers time and money, and our library offers a nearly endless supply of content. Whether you need space footage, videos of real people or even green screen footage, we have what you need. Wrap any documentary, finish any YouTube video, and nail any news story with high-quality HD and 4k stock video from Storyblocks.`,
    },
  ],
});
