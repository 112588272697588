import React from 'react';
import { twMerge } from 'tailwind-merge';

const GraphicStockItemCardLoader = ({
  className = '',
}: {
  className?: string;
}) => (
  <div
    className={twMerge(
      'shadow-plan-card animate-pulse aspect-w-16 aspect-h-9 bg-gray-100 rounded-lg',
      className
    )}
  >
    <div className="w-full h-full"></div>
  </div>
);

export default GraphicStockItemCardLoader;
