import { Builder } from '@builder.io/react';
import React from 'react';

import headerAlignmentFontInputs, {
  Alignment,
  FontFamily,
} from '../../utils/headerAlignmentFontInputs';
import CallToAction, {
  callToActionInputs,
  CallToActionProps,
} from '../elements/CallToAction.builder';
import ImageCardBuilder, {
  ImageCardProps,
} from '../elements/ImageCard.builder';
import BuilderSection from '../layouts/BuilderSection';
import ThreeColumnGrid from '../layouts/ThreeColumnGrid.builder';

interface ImageCardsProps {
  heading: string;
  subheading: string;
  headingAlignment: Alignment;
  fontFamily: FontFamily;
  imageCards: ImageCardProps[];
  numCardsShown: string;
  showCallToAction: boolean;
  callToAction?: CallToActionProps;
}

const ImageCards = ({
  heading,
  subheading,
  headingAlignment,
  fontFamily,
  imageCards,
  numCardsShown,
  showCallToAction,
  callToAction,
}: ImageCardsProps) => (
  <BuilderSection
    className={`${headingAlignment} w-full`}
    heading={heading}
    subheading={subheading}
    headingClassNames={`${fontFamily} text-5xl text-gray-900`}
  >
    <div className="flex flex-col w-full space-y-6">
      <ThreeColumnGrid>
        {imageCards?.length > 0 &&
          imageCards
            .slice(0, parseInt(numCardsShown, 10))
            .map(({ label, href, imageSrc, imageAlt, showNewLabel }, index) => (
              <ImageCardBuilder
                key={`${label}-${index}`}
                label={label}
                href={href}
                imageSrc={imageSrc}
                imageAlt={imageAlt}
                showNewLabel={showNewLabel}
              />
            ))}
      </ThreeColumnGrid>
      <CallToAction showCallToAction={showCallToAction} {...callToAction} />
    </div>
  </BuilderSection>
);

Builder.registerComponent(ImageCards, {
  name: 'Image Cards',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Collections',
    },
    {
      name: 'subheading',
      type: 'richText',
      defaultValue: '',
      onChange: (options) => {
        const characterLimit = 240;
        const subheadingText = options.get('subheading');
        const htmlTagRegex = /<.*?>/gi;

        const textOnly = subheadingText.replaceAll(htmlTagRegex, '');
        if (textOnly.length <= characterLimit) {
          return;
        }

        const tokenizedTags = [...subheadingText.matchAll(htmlTagRegex)].map(
          (item) => item[0]
        );
        const tokenizedText = subheadingText.split(htmlTagRegex);

        const trimmedTextNodes = tokenizedText.reduce((textNodes, text) => {
          const currentTextLength = textNodes.join('').length;
          return currentTextLength + text.length <= characterLimit
            ? textNodes.concat(text)
            : textNodes.concat(
                text.slice(0, characterLimit - currentTextLength)
              );
        }, []);

        const trimmedRichText = tokenizedTags
          .map((tag, i) => trimmedTextNodes[i] + tag)
          .join('');

        alert(`Max of ${characterLimit} characters allowed.`);
        options.set('subheading', trimmedRichText);
      },
    },
    {
      name: 'numCardsShown',
      friendlyName: 'Number of Image Cards to show',
      type: 'string',
      enum: ['3', '6', '9'],
      required: true,
      defaultValue: '3',
    },
    {
      name: 'imageCards',
      type: 'list',
      required: true,
      helperText:
        'A list of image cards that link to other pages. Can be used to feature collections, media types, etc.',
      defaultValue: [
        {
          label: 'Authentic Collection',
          href: '/images/collections/authentic-collection',
          imageSrc: '/media-type/high-quality.jpg',
          imageAlt: '',
          showNewLabel: false,
        },
        {
          label: 'Flat Lay Photos',
          href: '/images/collections/flat-lay-photos',
          imageSrc: '/media-type/high-quality.jpg',
          imageAlt: '',
          showNewLabel: false,
        },
        {
          label: 'Infographic Templates',
          href: '/images/collections/infographic-templates',
          imageSrc: '/media-type/high-quality.jpg',
          imageAlt: '',
          showNewLabel: false,
        },
      ],
      onChange: (options) => {
        const maxAllowed = 9;

        if (options.get('imageCards').length > maxAllowed) {
          options.set(
            'imageCards',
            options.get('imageCards').slice(0, maxAllowed)
          );
          alert(
            `Maximum number of image cards allowed is ${maxAllowed}. Modify an existing image card instead.`
          );
        }
      },
      subFields: [
        {
          name: 'label',
          type: 'string',
          required: true,
        },
        {
          name: 'href',
          type: 'string',
          required: true,
        },
        {
          friendlyName: 'Image Source',
          name: 'imageSrc',
          type: 'string',
          required: true,
          helperText:
            "Path to image asset, relative to '/assets/common/images'",
          regex: {
            pattern: '^/.*',
            options: '',
            message:
              "Make sure you include the leading slash and the path is relative to '/assets/common/images'",
          },
        },
        {
          name: 'imageAlt',
          type: 'string',
          required: true,
        },
        {
          name: 'showNewLabel',
          type: 'boolean',
          required: true,
          defaultValue: false,
        },
      ],
    },
    ...headerAlignmentFontInputs,
    ...callToActionInputs,
  ],
});
