import React, { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

export interface CarouselItemPropTypes {
  children: ReactElement;
  className?: string;
  isVisible?: boolean;
}

const CarouselItem = ({
  children,
  className,
  isVisible,
}: CarouselItemPropTypes): ReactElement => {
  return (
    <div
      className={twMerge('carousel-item inline-flex', className)}
      ref={(div) => {
        // inert is not yet recognized by react, so we apply via the ref callback.
        // Supporting browsers (mainstream evergreen) don't allow you to tab to elements if their parent is inert.
        // This allows us to only tab within the visible carousel items.
        if (isVisible) {
          div?.removeAttribute('inert');
        } else {
          div?.setAttribute('inert', '');
        }
      }}
    >
      {children}
    </div>
  );
};

export default CarouselItem;
