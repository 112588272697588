import { Builder } from '@builder.io/react';
import React, { ReactElement } from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import CallToAction, {
  callToActionInputs,
  CallToActionProps,
} from '../elements/CallToAction.builder';
import BuilderSection from '../layouts/BuilderSection';

interface Cards {
  cardIcon: string;
  cardHeader: string;
  cardParagraph: string;
}

enum Background {
  gray = 'bg-gray-100',
  image = 'image-background-light',
}

interface CardSectionProps {
  smallHeader: string;
  largeHeader: string;
  paragraph: string;
  showCallToAction: boolean;
  callToAction?: CallToActionProps;
  cards: Cards[];
  background: Background;
}

const CardSectionBuilder = ({
  smallHeader,
  largeHeader,
  paragraph,
  showCallToAction,
  callToAction,
  cards,
  background,
}: CardSectionProps): ReactElement => (
  <BuilderSection bgClassNames={background} className="text-gray-900">
    <>
      <header className="text-center">
        <p className="text-base p-3 m-0 font-semibold uppercase tracking-widest">
          {smallHeader}
        </p>
        <p className="text-5xl font-serif p-3 m-0 font-bold">{largeHeader}</p>
      </header>
      {paragraph && (
        <div
          className="text-center text-lg p-3 m-0 max-w-3xl mx-auto"
          dangerouslySetInnerHTML={{ __html: paragraph }}
        />
      )}
      <div className="p-3 m-0">
        <CallToAction {...callToAction} showCallToAction={showCallToAction} />
      </div>
      <div className="max-w-7xl mx-auto">
        <div
          className={`grid gap-3 m-6 ${
            cards.length === 4 ? 'md:grid-cols-4' : 'md:grid-cols-3'
          }`}
        >
          {cards?.length > 0 &&
            cards.map((card, idx) => (
              <React.Fragment key={idx}>
                <div className="bg-white border-solid border border-gray-400 rounded-lg p-6">
                  <ProgressiveImage
                    src={`${__ASSETS_COMMON_IMAGES_URL__}${card.cardIcon}`}
                    className="pb-4"
                    alt=""
                    width="32"
                    height="32"
                  />
                  <p className="text-2xl m-0 pb-2 font-bold">
                    {card.cardHeader}
                  </p>
                  <div
                    className="text-base m-0"
                    dangerouslySetInnerHTML={{ __html: card.cardParagraph }}
                  />
                </div>
              </React.Fragment>
            ))}
        </div>
      </div>
    </>
  </BuilderSection>
);

Builder.registerComponent(CardSectionBuilder, {
  name: 'Card Section',
  inputs: [
    {
      name: 'smallHeader',
      type: 'string',
      required: true,
      defaultValue: 'The Storyblocks Difference',
    },
    {
      name: 'largeHeader',
      type: 'string',
      defaultValue: 'Why Storyblocks',
    },
    {
      name: 'paragraph',
      type: 'richText',
    },
    {
      name: 'background',
      type: 'string',
      enum: [
        {
          label: 'gray',
          value: Background.gray,
        },
        {
          label: 'image',
          value: Background.image,
        },
      ],
      defaultValue: Background.gray,
    },
    {
      name: 'numCardsShown',
      friendlyName: 'Number of cards to show',
      type: 'string',
      enum: ['3', '4', '6'],
      required: true,
      defaultValue: '3',
    },

    {
      name: 'cards',
      type: 'list',
      required: true,
      defaultValue: [
        {
          cardHeader: 'Get Notifications Daily',
          cardParagraph:
            'Our subscription model gives you way more content for your money. We offer a flexible plan for every budget.',
          cardIcon: '/icons/sb-image.svg',
        },
        {
          cardHeader: 'Get Notifications Daily',
          cardParagraph:
            'Our subscription model gives you way more content for your money. We offer a flexible plan for every budget.',
          cardIcon: '/icons/sb-image.svg',
        },
        {
          cardHeader: 'Get Notifications Daily',
          cardParagraph:
            'Our subscription model gives you way more content for your money. We offer a flexible plan for every budget.',
          cardIcon: '/icons/sb-image.svg',
        },
      ],
      onChange: (options) => {
        if (options.get('cards').length > 6) {
          options.set('cards', options.get('cards').slice(0, 5));
          alert(
            'Maximum number of cards is 6. Modify an existing cards instead.'
          );
        }
      },
      subFields: [
        { name: 'cardIcon', type: 'string', required: true },
        {
          name: 'cardHeader',
          type: 'string',
          required: true,
        },
        { name: 'cardParagraph', type: 'richText', required: true },
      ],
    },
    ...callToActionInputs,
  ],
});
