const path = require('path');

const filePaths = {
  app: path.resolve('app/'),
  assets: path.resolve('assets/'),
  coreSites: path.resolve('CoreSites/'),
  nodeModules: path.resolve('node_modules/'),
  resources: path.resolve('resources/'),
};

/**
 * The specified paths tell purgeCSS where to look for used classes.
 * Because an overly-generic glob (ex. 'app/**\/*') will lead to long
 * build times, these globs need to stike a balance between too specific
 * and too general. The globs below make the following assumptions:
 *  1. All used styles in .php files are located within Views/, ViewModels/, Traits/, or /Widgets directories
 *  2. All used styles in react components are in files postfixed with (.js/.jsx/.ts/.tsx)
 */
const contentGlobs = [
  `${filePaths.app}/**/@(?(V|v)iew|?(V|v)iewModel|?(T|t)rait|?(S|s)ervice|?(W|w)idget)*/**/*.php`,
  `${filePaths.assets}/**/*.@(j|t)s?(x)`,
  `${filePaths.coreSites}/**/@(?(V|v)iew|?(V|v)iewModel|?(T|t)rait|?(S|s)ervice|?(W|w)idget)*/**/*.php`,
  `${filePaths.resources}/views/**/*.php`,

  /**
   * Third Party Packages (i.e. node_modules)
   *
   * Lists out third party packages to look for styles usage (including internally used packages)
   * Example: storywind uses tailwind styles that aren't necessarily used in stockblocks.
   *
   * [CAUTION] Make sure to:
   *   1. Exclude node_modules for third party packages
   *   2. Be specific in packages (ex. don't use /node_modules/**\/*.*)
   *   3. Double check that the /dist of the package actually contains the classes you're targeting
   *      (ex. react-dropdown dynamically creates the resulting class, so scanning its /dist won't work!)
   */
  `${filePaths.nodeModules}/@videoblocks/storywind/!(node_modules)/**/*.js`,
  `${filePaths.nodeModules}/@videoblocks/shared-components/!(node_modules)/**/*.js`,
  `${filePaths.nodeModules}/kuzco/!(node_modules)/**/*.js`,
  `${filePaths.nodeModules}/rc-slider/!(node_modules)/**/*.js`,
  `${filePaths.nodeModules}/react-autosuggest/!(node_modules)/**/*.js`,
  `${filePaths.nodeModules}/react-color/!(node_modules)/**/*.js`,
  `${filePaths.nodeModules}/react-datepicker/!(node_modules)/**/*.js`,
  `${filePaths.nodeModules}/react-table/!(node_modules)/**/*.js`,
  `${filePaths.nodeModules}/sal.js/!(node_modules)/**/*.js`,
];

module.exports = {
  contentGlobs,
  filePaths,
};
