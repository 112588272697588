import React, { ReactElement } from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

export interface ImageCardProps {
  className?: string;
  label: string;
  labelClassName?: string;
  href: string;
  imageSrc: string;
  imageAlt: string;
  imageClassName?: string;
  showNewLabel?: boolean;
}

const ImageCardBuilder = ({
  className = '',
  label = '',
  labelClassName = '',
  href = '',
  imageSrc = '',
  imageAlt = '',
  imageClassName = '',
  showNewLabel = false,
}: ImageCardProps): ReactElement => (
  <a
    className={`group aspect-w-16 aspect-h-9 relative overflow-hidden rounded-lg safari-rounded-border cursor-pointer ${className}`}
    href={href}
  >
    <ProgressiveImage
      className={`object-cover w-full h-full transform group-hover:scale-110 transition duration-300 ${imageClassName}`}
      src={`${__ASSETS_COMMON_IMAGES_URL__}${imageSrc}`}
      alt={imageAlt}
      shouldLazyLoad
    />

    {/* Opaque dark gray box gradient to make text stand out */}
    <div className="bg-gradient-to-t from-gray-900 to-transparent via-transparent absolute inset-0 w-full h-full"></div>

    {showNewLabel && (
      <div className="top-4 left-4 w-max absolute flex items-center justify-center h-6 px-2 text-sm font-bold text-black no-underline bg-white rounded-sm">
        New
      </div>
    )}
    <h3
      className={`absolute bottom-0 left-0 flex items-end p-4 m-0 text-xl text-white no-underline ${labelClassName}`}
    >
      {label}
    </h3>
  </a>
);

export default ImageCardBuilder;
