import { Builder } from '@builder.io/react';
import React from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import CallToAction, {
  callToActionFields,
  CallToActionProps,
} from '../../elements/CallToAction.builder';
import BuilderSection from '../../layouts/BuilderSection';

interface PricingCardProps extends CallToActionProps {
  heading: string;
  description: string;
  icon: string;
}

interface PricingProps {
  heading: string;
  cards: PricingCardProps[];
}

const PricingCard = ({
  heading,
  description,
  icon,
  color,
  href,
  showCallToAction = true,
  size,
  squared,
  text,
  variant,
}: PricingCardProps) => (
  <div className="rounded-xl flex flex-col items-center justify-center w-56 h-full px-4 py-8 font-sans text-center bg-white">
    <ProgressiveImage
      src={`${__ASSETS_COMMON_IMAGES_URL__}${icon}`}
      alt=""
      height="48"
      shouldLazyLoad
      className="h-12"
    />
    <h3 className="m-0 mt-4 text-xl font-bold">{heading}</h3>
    <p className="m-0 mt-2 mb-4 text-sm leading-6 text-gray-900">
      {description}
    </p>
    <CallToAction
      showCallToAction={showCallToAction}
      color={color}
      href={href}
      size={size}
      squared={squared}
      text={text}
      variant={variant}
    />
  </div>
);

const Pricing = ({ heading, cards }: PricingProps) => (
  <BuilderSection
    bgClassNames="bg-gray-200"
    heading={heading}
    headingClassNames="text-gray-900 text-center font-sans text-2xl"
  >
    <div className="md:flex-row flex-nowrap flex flex-col items-center justify-center w-full gap-8">
      {cards?.length > 0 &&
        cards.map((cardProps: PricingCardProps, index) => (
          <PricingCard {...cardProps} key={`pricing-card-${index}`} />
        ))}
    </div>
  </BuilderSection>
);

Builder.registerComponent(Pricing, {
  name: 'Pricing (Legacy)',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Plans & Pricing',
    },
    {
      name: 'cards',
      type: 'list',
      required: true,
      defaultValue: [
        {
          heading: 'Individual',
          description:
            'Plans with licensing for small businesses, freelances,and hobbyists.',
          icon: '/icons/individual-v2.svg',
          text: 'See Plans',
          href: '/pricing',
        },
        {
          heading: 'Business',
          description:
            'Plans with licensing for creative teams working at larger companies.',
          icon: '/icons/business.svg',
          text: 'Learn More',
          href: '/pricing',
        },
      ],
      subFields: [
        {
          name: 'heading',
          type: 'string',
          required: true,
        },
        {
          name: 'description',
          type: 'longText',
          required: true,
        },
        {
          name: 'icon',
          type: 'string',
          required: true,
          helperText:
            "Path to image asset, relative to '/assets/common/images'",
          regex: {
            pattern: '^/.*',
            options: '',
            message:
              "Make sure you include the leading slash and the path is relative to '/assets/common/images'",
          },
        },
        ...callToActionFields,
      ],
    },
  ],
});
