import { Builder } from '@builder.io/react';
import React from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import { targetAttributeOptions } from '../../constants';
import { lazyLoadDefaultConfig } from '../../constants';
import headerAlignmentFontInputs, {
  Alignment,
  FontFamily,
} from '../../utils/headerAlignmentFontInputs';
import CallToAction, {
  callToActionInputs,
  CallToActionProps,
} from '../elements/CallToAction.builder';
import BuilderSection from '../layouts/BuilderSection';

interface Article {
  name: string;
  articleHeading?: string;
  imageSourceAlt?: string;
  link: string;
  imageSrc: string;
  target: string;
  relativeLink: string;
}

interface ArticlesProps {
  heading: string;
  subheading: string;
  headingAlignment: Alignment;
  fontFamily: FontFamily;
  articles: Article[];
  lazyLoadImage: boolean;
  showCallToAction: boolean;
  callToAction?: CallToActionProps;
}

const Articles = ({
  heading,
  subheading,
  headingAlignment,
  fontFamily,
  articles,
  lazyLoadImage,
  showCallToAction,
  callToAction,
}: ArticlesProps) => {
  return (
    <BuilderSection
      className={`flex flex-col text-gray-900 ${headingAlignment}`}
      heading={heading}
      subheading={subheading}
      headingClassNames={`${fontFamily} text-5xl`}
    >
      <div className="space-y-4 justify-center">
        <div className="lg:grid-cols-3 grid grid-cols-1 gap-8 mx-auto mb-8">
          {articles.map(
            (
              {
                name,
                articleHeading,
                imageSourceAlt = '',
                link,
                imageSrc,
                target,
                relativeLink,
              },
              index
            ) => (
              <div className="text-left" key={`article-${index}-${name}`}>
                <div className="overflow-hidden rounded-lg">
                  <a href={link} target={target} rel={relativeLink}>
                    <ProgressiveImage
                      className="w-full"
                      src={__ASSETS_COMMON_IMAGES_URL__ + imageSrc}
                      shouldLazyLoad={lazyLoadImage}
                      width="332"
                      height="184"
                      alt={imageSourceAlt}
                    />
                  </a>
                </div>
                {articleHeading && (
                  <header className="font-semibold text-sm uppercase tracking-widest my-6">
                    {articleHeading}
                  </header>
                )}
                <a
                  className="hover:underline text-gray-900"
                  href={link}
                  target={target}
                  rel={relativeLink}
                >
                  <p className="text-lg font-semibold m-0">{name}</p>
                </a>
              </div>
            )
          )}
        </div>
        <CallToAction showCallToAction={showCallToAction} {...callToAction} />
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(Articles, {
  name: 'Articles',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Tips & Tutorials',
    },
    {
      name: 'subheading',
      type: 'richText',
      defaultValue: '',
      onChange: (options) => {
        const characterLimit = 240;
        const subheadingText = options.get('subheading');
        const htmlTagRegex = /<.*?>/gi;

        const textOnly = subheadingText.replaceAll(htmlTagRegex, '');
        if (textOnly.length <= characterLimit) {
          return;
        }

        const tokenizedTags = [...subheadingText.matchAll(htmlTagRegex)].map(
          (item) => item[0]
        );
        const tokenizedText = subheadingText.split(htmlTagRegex);

        const trimmedTextNodes = tokenizedText.reduce((textNodes, text) => {
          const currentTextLength = textNodes.join('').length;
          return currentTextLength + text.length <= characterLimit
            ? textNodes.concat(text)
            : textNodes.concat(
                text.slice(0, characterLimit - currentTextLength)
              );
        }, []);

        const trimmedRichText = tokenizedTags
          .map((tag, i) => trimmedTextNodes[i] + tag)
          .join('');

        alert(`Max of ${characterLimit} characters allowed.`);
        options.set('subheading', trimmedRichText);
      },
    },
    {
      name: 'articles',
      type: 'list',
      required: true,
      defaultValue: [
        {
          name: '[Article Name]',
          articleHeading: '[Article Heading]',
          link: 'https://www.storyblocks.com/resources/blog',
          imageSrc: '/youtube/monetization.jpg',
          target: '_blank',
          relativeLink: 'noopener',
        },
        {
          name: '[Article Name]',
          articleHeading: '[Article Heading]',
          link: 'https://www.storyblocks.com/resources/blog',
          imageSrc: '/youtube/monetization.jpg',
          target: '_blank',
          relativeLink: 'noopener',
        },
        {
          name: '[Article Name]',
          articleHeading: '[Article Heading]',
          link: 'https://www.storyblocks.com/resources/blog',
          imageSrc: '/youtube/monetization.jpg',
          target: '_blank',
          relativeLink: 'noopener',
        },
      ],
      onChange: (options) => {
        if (options.get('articles').length > 3) {
          options.set('articles', options.get('articles').slice(0, 3));
          alert(
            'Maximum number of articles is 3. Modify an existing article instead.'
          );
        }
      },
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
          defaultValue: '[Article Name]',
        },
        {
          name: 'articleHeading',
          type: 'string',
          defaultValue: '[Article Heading]',
        },
        {
          name: 'link',
          type: 'string',
          required: true,
          defaultValue: 'https://www.storyblocks.com/resources/blog',
        },
        {
          name: 'imageSrc',
          type: 'string',
          required: true,
          defaultValue: '/youtube/monetization.jpg',
        },
        {
          name: 'imageSourceAlt',
          type: 'string',
          helperText: 'Image alternative text',
        },
        {
          name: 'target',
          type: 'string',
          enum: targetAttributeOptions,
          required: true,
          defaultValue: '_blank',
          helperText:
            'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target',
        },
        {
          name: 'relativeLink',
          type: 'string',
          required: true,
          defaultValue: 'noopener',
          helperText:
            'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-rel',
        },
      ],
    },
    {
      ...lazyLoadDefaultConfig,
    },
    ...headerAlignmentFontInputs,
    ...callToActionInputs,
  ],
});
