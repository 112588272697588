import { Builder } from '@builder.io/react';
import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useDebouncedCallback } from 'use-debounce';

import { Telemetry } from '@videoblocks/kafka-rest-client';

import SearchAPI from '../../../app/Search/SearchAPI';
import VideoStockItemCard from '../../../app/Search/components/card/VideoStockItemCard';
import { getCategoriesForContentType } from '../../../app/Search/entities/VideoSearchFilterOptions';
import VideoSearchOptions from '../../../app/Search/entities/VideoSearchOptions';
import { SiteEnum } from '../../../common/SiteConstants/SiteConstants';
import useMedia from '../../../common/hooks/useMedia';
import { usePrevious } from '../../../common/hooks/usePrevious';
import { isEqual } from '../../../common/utils';
import {
  browseCategoriesMediaTypesOptions,
  defaultSortOptions,
  videoSortOptions,
} from '../../constants';
import headerAlignmentFontInputs, {
  Alignment,
  FontFamily,
} from '../../utils/headerAlignmentFontInputs';
import CallToAction, {
  callToActionFields,
  CallToActionProps,
} from '../elements/CallToAction.builder';
import BuilderSection from '../layouts/BuilderSection';
import StockItemCardLoader from '../loading-skeletons/StockItemCardLoader';

interface Category {
  label: string;
  mediaType: string;
  category: string;
  searchTerm: string;
  sort: string;
  minQualityScore: number;
  showCallToAction: boolean;
  callToAction: CallToActionProps;
  callToActionParams?: object;
}

interface BrowseCategoriesProps {
  fontFamily: FontFamily;
  headingAlignment?: Alignment;
  heading: string;
  categories: Category[];
  categoriesFullWidth?: boolean;
  highlightFirstVideo?: boolean;
  numResultsMobile: number;
  numResults: string;
  customContent?: any;
}

const calculateNewSelectedCategory = (
  categories: Category[],
  prevCategories: Category[],
  selectedCategory: number
) => {
  let categoryIndex = -1;
  if (!prevCategories || !categories) {
    return categoryIndex;
  }

  if (!prevCategories.length || !prevCategories.length) {
    categoryIndex = 0;
  }

  if (categories.length === prevCategories.length) {
    categoryIndex = prevCategories.findIndex(
      (prevCategory, index) => !isEqual(prevCategory, categories[index])
    );
  } else if (categories.length > prevCategories.length) {
    categoryIndex = categories.length - 1;
  } else {
    const deletedCategoryIndex = prevCategories.findIndex(
      (prevCategory, index) => {
        return (
          index >= categories.length ||
          !isEqual(prevCategory, categories[index])
        );
      }
    );
    if (selectedCategory > deletedCategoryIndex) {
      categoryIndex = selectedCategory - 1;
    } else if (selectedCategory === deletedCategoryIndex) {
      categoryIndex = 0;
    }
  }

  return categoryIndex;
};

const BrowseCategories = ({
  headingAlignment = Alignment.Center,
  heading,
  fontFamily,
  categories,
  categoriesFullWidth = false,
  highlightFirstVideo = false,
  numResultsMobile,
  numResults,
  customContent,
}: BrowseCategoriesProps) => {
  const [content, setContent] = useState(customContent || []);
  const [selectedCategory, setSelectedCategory] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  // i.e. tailwind md breakpoint https://storywind.netlify.app/?path=/story/design-grids--page#medium-672-1024px
  const isSmallScreen = useMedia('(max-width: 673px)', false);

  const desktopNumResults = parseInt(numResults, 10);

  const getSearchResults = (
    categoryObject: Category,
    desktopNumResults: number,
    mobileNumResults: number
  ) => {
    const resultsPerPage = Math.max(desktopNumResults, mobileNumResults);
    const {
      category: categories,
      mediaType: contentType,
      searchTerm,
      sort,
      minQualityScore,
    } = categoryObject;
    setContent([]);

    /** NOTE: object keys should match object keys in VideoMenuFilters.js */
    const searchParameters = new VideoSearchOptions().buildSearchApiParameters({
      categories,
      contentType,
      resultsPerPage,
      searchOrigin: Builder.isEditing
        ? 'builder_editing'
        : 'builder-video-content-grid',
      searchTerm,
      sort,
      minQualityScore,
    });

    setLoading(true);
    SearchAPI.searchBuilder(SiteEnum.Videoblocks, searchParameters)
      .then((resp) => resp.json())
      .then((resp) => {
        setContent(resp?.data?.stockItems || []);
      })
      .catch(() => {
        // [MPB] TODO: determine how to handle search loading errors.
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFetchSearchResultsRequest = useDebouncedCallback(
    getSearchResults,
    350,
    { maxWait: 1000 }
  );

  const prevCategories = usePrevious(categories);

  React.useEffect(() => {
    const newSelectedCategory = calculateNewSelectedCategory(
      categories,
      prevCategories,
      selectedCategory
    );
    newSelectedCategory >= 0 && setSelectedCategory(newSelectedCategory);
    handleFetchSearchResultsRequest(
      categories[
        newSelectedCategory >= 0 ? newSelectedCategory : selectedCategory
      ],
      desktopNumResults,
      numResultsMobile
    );
  }, [categories, numResultsMobile, numResults]);

  const handleCategoryClick = (index) => {
    setSelectedCategory(index);
    handleFetchSearchResultsRequest(
      categories[index],
      desktopNumResults,
      numResultsMobile
    );
  };

  const handCtaClick = React.useCallback(() => {
    const telemetryMediaType = categories[selectedCategory]?.mediaType || 'all';
    const telemetryCategoryId = categories[selectedCategory]?.category || 'all';
    Telemetry.increment(
      `browseCategories.clicked.${telemetryMediaType}.${telemetryCategoryId}`
    );
  }, [selectedCategory]);

  return (
    <BuilderSection
      heading={heading}
      headingClassNames={`${headingAlignment} ${fontFamily} text-5xl text-gray-900`}
    >
      <div className="w-full space-y-6">
        <div
          className={twMerge(
            'gap-x-2 gap-y-4 flex flex-row flex-wrap items-center mb-8',
            categoriesFullWidth ? 'justify-items-stretch' : 'justify-center'
          )}
        >
          {categories?.length > 0 &&
            categories.map((category, index) => (
              <button
                onClick={() => handleCategoryClick(index)}
                key={category.label}
                className={twMerge(
                  'focus:bg-gray-800 focus:text-white hover:bg-gray-800 hover:text-white duration-250 px-6 py-2 text-base text-black no-underline transition-all bg-gray-200 border border-transparent border-solid rounded-full',
                  selectedCategory === index && 'bg-gray-800 text-white',
                  categoriesFullWidth && 'flex-grow'
                )}
              >
                {category.label}
              </button>
            ))}
        </div>
        <div className="stock-item-group-wrapper card-design leading">
          {loading ? (
            <div className="grid grid-cols-1 gap-4">
              <div className="md:grid-cols-3 grid grid-cols-1 gap-4">
                {[
                  ...Array(
                    isSmallScreen ? numResultsMobile : desktopNumResults
                  ),
                ].map((_, index) => (
                  <StockItemCardLoader
                    key={`video-card-loader-${index}`}
                    className={
                      highlightFirstVideo && index === 0
                        ? 'row-span-2 col-span-2'
                        : ''
                    }
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="stock-item-v2 grid grid-cols-1 gap-4">
              <div className="md:grid-cols-3 grid grid-cols-1 gap-4">
                {content
                  .slice(
                    0,
                    isSmallScreen ? numResultsMobile : desktopNumResults
                  )
                  .map((item, index) => {
                    const classNames = ['m-0', 'p-0'];
                    if (highlightFirstVideo && index === 0) {
                      classNames.push('row-span-2', 'col-span-2');
                    }

                    return (
                      <VideoStockItemCard
                        {...item}
                        classNames={classNames}
                        containerResponsiveClassNames={[]}
                        isCollapsedStockItemLoadMoreCard={false}
                        shouldInvertControlsVisibility={true}
                        stockItem={item.stockItem}
                        thumbnailClassNames="overflow-hidden rounded-lg"
                        key={`video-stock-item-card-${item.id || index}`}
                      />
                    );
                  })}
              </div>
            </div>
          )}
        </div>
        <CallToAction
          {...categories[selectedCategory].callToAction}
          onClick={handCtaClick}
        />
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(BrowseCategories, {
  name: 'Browse Categories',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Browse Videos',
    },
    ...headerAlignmentFontInputs,
    {
      name: 'numResultsMobile',
      friendlyName: 'Number of Results (mobile)',
      type: 'number',
      defaultValue: 3,
      required: true,
    },
    {
      name: 'numResults',
      friendlyName: 'Number of Results (desktop)',
      type: 'string',
      enum: ['6', '9', '12', '15'],
      defaultValue: '6',
      required: true,
    },
    {
      name: 'mediaType',
      type: 'string',
      required: true,
      enum: browseCategoriesMediaTypesOptions,
      defaultValue: 'templates',
    },
    {
      name: 'categoriesFullWidth',
      type: 'boolean',
      required: false,
      defaultValue: false,
    },
    {
      name: 'highlightFirstVideo',
      type: 'boolean',
      required: false,
      defaultValue: false,
    },
    {
      name: 'categories',
      friendlyName: 'Categories',
      type: 'list',
      onChange: (options) => {
        if (options.get('categories').length > 4) {
          options.set('categories', options.get('categories').slice(0, 4));
          alert("can't be more than 4");
        } else if (options.get('categories').length < 2) {
          alert("can't be less than 2");
        }
      },
      defaultValue: [
        {
          label: 'All',
          searchTerm: '',
          sort: 'most_relevant',
          showCallToAction: true,
          callToAction: {
            text: 'Explore',
            href: '/',
            color: 'secondary',
            size: 'medium',
            variant: 'outlined',
            target: '_self',
            rel: '',
            squared: false,
          },
        },
        {
          label: '[category]',
          searchTerm: '',
          sort: 'most_relevant',
          showCallToAction: true,
          callToAction: {
            text: 'Explore',
            href: '/',
            color: 'secondary',
            size: 'medium',
            variant: 'outlined',
            target: '_self',
            rel: '',
            squared: false,
          },
        },
      ],
      subFields: [
        {
          name: 'label',
          friendlyName: 'Label',
          type: 'string',
          defaultValue: '[Category]',
        },
        {
          name: 'mediaType',
          type: 'string',
          required: true,
          enum: browseCategoriesMediaTypesOptions,
          defaultValue: 'templates',
        },
        {
          name: 'category',
          type: 'string',
          showIf: `options.get('mediaType') === 'footage'`,
          enum: getCategoriesForContentType('footage'),
          defaultValue: '',
        },
        {
          name: 'category',
          type: 'string',
          showIf: `options.get('mediaType') === 'motion-backgrounds'`,
          enum: getCategoriesForContentType('motion-backgrounds'),
          defaultValue: '',
        },
        {
          name: 'category',
          type: 'string',
          showIf: `options.get('mediaType') === 'templates'`,
          enum: getCategoriesForContentType('templates'),
          defaultValue: '',
        },
        {
          name: 'searchTerm',
          type: 'string',
          defaultValue: '',
        },
        {
          name: 'sort',
          type: 'string',
          defaultValue: 'most_relevant',
          required: true,
          enum: [...defaultSortOptions, ...videoSortOptions],
        },
        {
          name: 'minQualityScore',
          type: 'number',
          defaultValue: null,
          min: 0,
          max: 1,
        },
        {
          name: 'showCallToAction',
          type: 'boolean',
          defaultValue: true,
        },
        {
          name: 'callToAction',
          type: 'object',
          defaultValue: {
            text: 'Explore',
            href: '/',
            color: 'primary',
            size: 'medium',
            variant: 'contained',
            target: '_self',
            rel: '',
            squared: false,
          },
          showIf: `options.get('showCallToAction')`,
          subFields: callToActionFields,
        },
      ],
    },
  ],
});
