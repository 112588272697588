import { Builder } from '@builder.io/react';
import React from 'react';
import Typewriter from 'typewriter-effect';

import BuilderSection from '../layouts/BuilderSection';

import './HomepageTypewriter.less';

interface TypewriterProps {
  words: string;
}

export const HomepageTypewriter = ({ words = '' }: TypewriterProps) => (
  <BuilderSection
    bgClassNames="col-span-full flex justify-center"
    className="w-max"
  >
    <h2 className="md:text-4xl md:max-w-3xl lg:px-0 px-8 text-2xl font-bold text-center">
      For&nbsp;
      <Typewriter
        component="span"
        options={{
          strings: words.split(','),
          autoStart: true,
          loop: true,
          // @ts-ignore
          pauseFor: 4000,
        }}
      />
      &nbsp;who want to <br className="md:hidden" /> make&nbsp;
      <br className="md:block hidden" />
      more videos faster than ever before.
    </h2>
  </BuilderSection>
);

Builder.registerComponent(HomepageTypewriter, {
  name: 'Homepage Typewriter',
  inputs: [
    {
      name: 'words',
      type: 'string',
      required: true,
      defaultValue: 'creators,marketers,freelancers,teams,agencies',
      helperText: 'Comma-separated list of words to cycle through',
    },
  ],
});
