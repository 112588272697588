import React, { ReactElement } from 'react';

export interface LogosType {
  imageIndustryLogo: string;
  altText: string;
}

interface LogosProps {
  logosArr: LogosType[];
  numLogosShown?: number;
}

const Logos = ({ logosArr, numLogosShown = 8 }: LogosProps): ReactElement => (
  <div
    className={`py-4 md:px-9 grid grid-cols-1 ${
      numLogosShown == 6 ? 'lg:grid-cols-3' : 'lg:grid-cols-4'
    } gap-2 mx-auto`}
  >
    {logosArr.slice(0, numLogosShown).map((logo) => (
      <div className="mx-auto" key={logo.altText}>
        <img
          src={`${__ASSETS_COMMON_IMAGES_URL__}${logo.imageIndustryLogo}`}
          alt={logo.altText}
        />
      </div>
    ))}
  </div>
);
export default Logos;
