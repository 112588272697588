import { Builder, withChildren } from '@builder.io/react';
import React, { ReactElement } from 'react';

import BuilderSection from '../../layouts/BuilderSection';

interface DualMediaAndTextProps {
  children: ReactElement;
  heading: string;
}

const DualMediaAndText = ({ children, heading }: DualMediaAndTextProps) => (
  <BuilderSection
    bgClassNames="w-full relative my-16 md:my-20 lg:my-24 py-16 md:py-20 lg:py-24"
    className="flex flex-col items-center text-white"
    heading={heading}
    headingClassNames="text-white text-center lg:text-left font-sans text-2xl"
  >
    <>
      <div className="-z-1 skew-y-10 absolute inset-0 w-full h-full transform bg-gray-900"></div>
      {children}
    </>
  </BuilderSection>
);

const DualMediaAndTextWithChildren = withChildren(DualMediaAndText);

Builder.registerComponent(DualMediaAndTextWithChildren, {
  name: 'Media And Text (Dual) Legacy',

  childRequirements: {
    message: "You can only have 'Media and Text' components as children",
    query: {
      'component.name': { $in: ['Media And Text (Legacy)'] },
    },
  },

  inputs: [
    {
      name: 'heading',
      type: 'string',
      defaultValue: '',
    },
  ],

  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Media And Text (Legacy)',
        options: {
          heading: 'Demand-driven library',
          headingColor: 'text-white',
          content: `
                        We're always adapting to your needs and adding new content to our library to ensure that we are curating diverse, representative video, music and imagery from contributors around the world.
                        <div class="flex items-center w-64 mt-6 md:hidden lg:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="w-8 flex-shrink-0"><clipPath id="a"><path d="M0 0h32v32H0z"></path></clipPath><g clip-path="url(#a)" fill="none"><path d="M0 0h32v32H0z"></path><g stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M0 32h31.3a.68.68 0 0 0 .7-.7V0"></path><path d="M22.26 32V7a1.41 1.41 0 0 1 .41-1 1.38 1.38 0 0 1 1-.4h2.78A1.38 1.38 0 0 1 27.83 7v25m-15.31 0V15.3a1.4 1.4 0 0 1 .41-1 1.39 1.39 0 0 1 1-.41h2.77a1.4 1.4 0 0 1 1.39 1.39V32M3 32V21.85a.73.73 0 0 1 .37-.6 1.57 1.57 0 0 1 .88-.25h2.5a1.57 1.57 0 0 1 .88.25.73.73 0 0 1 .37.6V32"></path></g></g></svg>
                            <span class="pl-4 text-white">We’re quadrupling our diverse content by 2022</span>
                        </div>
                    `,
          imageSource: '/home-v2/images/demand-desktop.png',
          mediaFirst: true,
          showCallToAction: true,
          callToAction: {
            text: 'Learn More',
            href: '/diverse-stock-footage',
            color: 'light',
            variant: 'outlined',
          },
        },
      },
    },
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Media And Text (Legacy)',
        options: {
          heading: 'Advanced effects made simple',
          headingColor: 'text-white',
          content:
            'Our huge selection of royalty free video gives you what you need to shape your story. We know that it takes time and money to create a great video, which is why we are here to speed up your process. With Storyblocks, you’ll have access to an expansive library of stock clips including 360°/VR, aerial videos, timelapses, slow motion footage, and much more, all included with your subscription. With our unlimited plans, you can download as many clips as you like — allowing you to edit without price constraints.',
          imageSource: '/home-v2/content-carousel/content-video-mobile.jpg',
          mediaFirst: false,
          showCallToAction: false,
        },
      },
    },
  ],
});
