import React, { ReactElement } from 'react';

import KeyboardArrowRightIcon from '@videoblocks/react-icons/KeyboardArrowRight';

interface ExploreLinkProps {
  href: string;
  rel: string;
  showExploreLink: boolean;
  target: string;
  text: string;
}

const ExploreLink = ({
  href,
  showExploreLink,
  rel,
  target,
  text,
}: ExploreLinkProps): ReactElement => (
  <>
    {showExploreLink && text && (
      <a
        href={href}
        className="flex justify-end items-center gap-1 text-base font-bold text-gray-700 cursor-pointer"
        rel={rel}
        target={target}
      >
        {text}
        <KeyboardArrowRightIcon className="fill-current w-3.5" />
      </a>
    )}
  </>
);

const exploreLinkInputs = [
  {
    name: 'showExploreLink',
    type: 'boolean',
    defaultValue: 'true',
  },
  {
    name: 'exploreLink',
    type: 'object',
    defaultValue: {
      text: 'Explore all content',
      href: '/',
      target: '_self',
      rel: '',
    },
    showIf: (options) => options.get('showExploreLink'),
    subFields: [
      {
        name: 'text',
        type: 'string',
        required: true,
      },
      {
        name: 'href',
        type: 'string',
        required: true,
      },
      {
        name: 'target',
        type: 'string',
        enum: ['_self', '_blank', '_parent', '_top'],
        helperText:
          'For explanation of options, see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target',
      },
      {
        name: 'rel',
        type: 'string',
        helperText:
          'For documentation on accepted values, see https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types for link types allowed in <a> elements',
      },
    ],
  },
];

export default ExploreLink;

export { ExploreLinkProps, exploreLinkInputs };
