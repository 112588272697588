import { Builder } from '@builder.io/react';
import React from 'react';

import BareBuilderSection from '../layouts/BareBuilderSection';

interface SiteLink {
  href: string;
  text: string;
}

interface SiteMapCategoryProps {
  heading: SiteLink;
  siteLinks: SiteLink[];
}

const SiteMapCategory = ({ heading, siteLinks }: SiteMapCategoryProps) => (
  <BareBuilderSection>
    <>
      <div className="max-w-7xl md:px-6 w-full px-4 py-10 mx-auto">
        <a
          className="flex w-full space-y-8 mb-6 text-left text-2xl text-black font-bold"
          href={heading.href}
        >
          {heading.text}
        </a>
        <div className="grid md:grid-cols-4 grid-cols-2">
          {siteLinks?.map(({ href, text }, index) => (
            <div className="pr-4 w-full" key={href}>
              <a
                key={`link-${index}-${text}`}
                href={href}
                rel="noopener noreferrer"
                className="text-black"
              >
                {text}
              </a>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full bg-gray-200 h-10" />
    </>
  </BareBuilderSection>
);

Builder.registerComponent(SiteMapCategory, {
  name: 'Site Map Category',
  inputs: [
    {
      name: 'heading',
      type: 'object',
      required: true,
      defaultValue: { href: '/', text: '[Category]' },
      subFields: [
        {
          name: 'href',
          type: 'string',
          defaultValue: '/',
        },
        {
          name: 'text',
          type: 'string',
          required: true,
          defaultValue: '[Category]',
        },
      ],
    },
    {
      name: 'siteLinks',
      type: 'list',
      required: true,
      defaultValue: [{ href: '/', text: '[Link]' }],
      subFields: [
        {
          name: 'href',
          type: 'string',
          required: true,
          defaultValue: '/',
        },
        {
          name: 'text',
          type: 'string',
          required: true,
          defaultValue: '[Link]',
        },
      ],
    },
  ],
});

export default SiteMapCategory;
