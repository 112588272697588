import { Builder } from '@builder.io/react';
import React from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import { targetAttributeOptions } from '../../../constants';
import ExploreLink, {
  ExploreLinkProps,
  exploreLinkInputs,
} from '../../elements/ExploreLink.builder';
import BuilderSection from '../../layouts/BuilderSection';

interface Article {
  name: string;
  link: string;
  imageSrc: string;
  target: string;
  relativeLink: string;
}

interface ArticlesProps {
  heading: string;
  subheading: string;
  articles: Article[];
  lazyLoad: boolean;
  showExploreLink: boolean;
  exploreLink: ExploreLinkProps;
}

const Articles = ({
  heading,
  subheading,
  articles,
  lazyLoad,
  showExploreLink,
  exploreLink,
}: ArticlesProps) => {
  return (
    <BuilderSection
      className="flex flex-col justify-center"
      heading={heading}
      subheading={subheading}
    >
      <div className="space-y-4">
        <div className="lg:grid-cols-3 grid grid-cols-1 gap-8 mx-auto">
          {articles.map(
            ({ name, link, imageSrc, target, relativeLink }, index) => (
              <div
                key={`article-${index}-${name}`}
                className="shadow-plan-card overflow-hidden rounded-lg"
              >
                <a
                  className="hover:underline text-gray-700"
                  href={link}
                  target={target}
                  rel={relativeLink}
                >
                  <ProgressiveImage
                    className="w-full"
                    src={__ASSETS_COMMON_IMAGES_URL__ + imageSrc}
                    shouldLazyLoad={lazyLoad}
                    alt=""
                  />
                  <p className="p-4 pb-12 text-base text-center">{name}</p>
                </a>
              </div>
            )
          )}
        </div>
        <ExploreLink showExploreLink={showExploreLink} {...exploreLink} />
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(Articles, {
  name: 'Articles (Legacy)',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Tips & Tutorials',
    },
    {
      name: 'subheading',
      type: 'richText',
      defaultValue: '',
      onChange: (options) => {
        const characterLimit = 240;
        const subheadingText = options.get('subheading');
        const htmlTagRegex = /<.*?>/gi;

        const textOnly = subheadingText.replaceAll(htmlTagRegex, '');
        if (textOnly.length <= characterLimit) {
          return;
        }

        const tokenizedTags = [...subheadingText.matchAll(htmlTagRegex)].map(
          (item) => item[0]
        );
        const tokenizedText = subheadingText.split(htmlTagRegex);

        const trimmedTextNodes = tokenizedText.reduce((textNodes, text) => {
          const currentTextLength = textNodes.join('').length;
          return currentTextLength + text.length <= characterLimit
            ? textNodes.concat(text)
            : textNodes.concat(
                text.slice(0, characterLimit - currentTextLength)
              );
        }, []);

        const trimmedRichText = tokenizedTags
          .map((tag, i) => trimmedTextNodes[i] + tag)
          .join('');

        alert(`Max of ${characterLimit} characters allowed.`);
        options.set('subheading', trimmedRichText);
      },
    },
    {
      name: 'articles',
      type: 'list',
      required: true,
      defaultValue: [
        {
          name: '[Article Name]',
          link: 'https://www.storyblocks.com/resources/blog',
          imageSrc: '/youtube/monetization.jpg',
          target: '_blank',
          relativeLink: 'noopener',
        },
        {
          name: '[Article Name]',
          link: 'https://www.storyblocks.com/resources/blog',
          imageSrc: '/youtube/monetization.jpg',
          target: '_blank',
          relativeLink: 'noopener',
        },
        {
          name: '[Article Name]',
          link: 'https://www.storyblocks.com/resources/blog',
          imageSrc: '/youtube/monetization.jpg',
          target: '_blank',
          relativeLink: 'noopener',
        },
      ],
      onChange: (options) => {
        if (options.get('articles').length > 3) {
          options.set('articles', options.get('articles').slice(0, 3));
          alert(
            'Maximum number of articles is 3. Modify an existing article instead.'
          );
        }
      },
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
          defaultValue: '[Article Name]',
        },
        {
          name: 'link',
          type: 'string',
          required: true,
          defaultValue: 'https://www.storyblocks.com/resources/blog',
        },
        {
          name: 'imageSrc',
          type: 'string',
          required: true,
          defaultValue: '/youtube/monetization.jpg',
        },
        {
          name: 'target',
          type: 'string',
          enum: targetAttributeOptions,
          required: true,
          defaultValue: '_blank',
          helperText:
            'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target',
        },
        {
          name: 'relativeLink',
          type: 'string',
          required: true,
          defaultValue: 'noopener',
          helperText:
            'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-rel',
        },
      ],
    },
    {
      name: 'lazyLoad',
      type: 'boolean',
      required: false,
      defaultValue: true,
    },
    ...exploreLinkInputs,
  ],
});
