import { Builder } from '@builder.io/react';
import React, { ReactElement } from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import { lazyLoadDefaultConfig } from '../../../constants';
import CallToAction, {
  callToActionInputs,
  CallToActionProps,
} from '../../elements/CallToAction.builder';
import BuilderSection from '../../layouts/BuilderSection';
import { getBackgroundStyles } from '../styleUtils';

interface YoutubeProps {
  paragraph?: string;
  videoId: string;
  darkMode: boolean;
  showCallToAction: boolean;
  callToAction?: CallToActionProps;
  imagesFirst: boolean;
  heading: string;
  subheading?: string;
  content: string;
  isPrimaryView: boolean;
  backgroundImageSrc: string;
  backgroundImageSrcAlt?: string;
  lazyLoadImage: boolean;
}

function headerValue(subheading: string) {
  return subheading ? 'h3' : 'h2';
}

export const Youtube = ({
  paragraph,
  videoId,
  darkMode,
  showCallToAction,
  callToAction,
  imagesFirst,
  heading,
  subheading,
  content,
  isPrimaryView,
  backgroundImageSrc,
  backgroundImageSrcAlt = '',
  lazyLoadImage,
}: YoutubeProps): ReactElement => {
  const Title = headerValue(subheading);

  return (
    <BuilderSection
      bgClassNames={getBackgroundStyles(darkMode, backgroundImageSrc)}
      headingClassNames="text-5xl font-serif text-center mb-8 w-full"
    >
      <>
        {isPrimaryView && (
          <div>
            <Title className="font-bold text-3xl md:text-5xl mb-6 font-serif text-center">
              {heading}
            </Title>
            <div className={darkMode ? 'text-white' : 'text-gray-900'}>
              {paragraph !== '<p><br></p>' && (
                <div
                  className="text-lg mb-8 mx-auto"
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                />
              )}
              <div className="max-w-4xl w-full px-8 justify-center mx-auto mb-8">
                <div className="aspect-w-16 aspect-h-9 w-full">
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${videoId}`}
                    title="Youtube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            <CallToAction
              {...callToAction}
              showCallToAction={showCallToAction}
            />
          </div>
        )}

        {!isPrimaryView && (
          <div>
            <div className="inline absolute inset-0 w-full flex flex-col -z-1">
              <ProgressiveImage
                src={backgroundImageSrc}
                alt={backgroundImageSrcAlt}
                shouldLazyLoad={lazyLoadImage}
              />
            </div>
            <div className="flex justify-center flex-nowrap px-6 md:px-24 py-8 md:py-10 w-full flex flex-col md:flex-row items-center gap-8 justify-start">
              <div
                className={`${
                  imagesFirst ? 'md:order-1' : 'md:order-2'
                } md:w-1/2 w-full`}
              >
                <div className="overflow-hidden">
                  <div className="lg:block aspect-w-16 aspect-h-9 rounded-3xl">
                    <iframe
                      className="inset-0 object-cover rounded-2.5xl w-full h-full bg-gray-400"
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${videoId}`}
                      title="Youtube video player"
                      frameBorder="0"
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>

              <div
                className={`${
                  imagesFirst ? 'md:order-2' : 'md:order-1'
                } md:w-1/2 w-full`}
              >
                {subheading && (
                  <h2 className="font-semibold text-sm md:text-base uppercase mb-6 tracking-widest">
                    {subheading}
                  </h2>
                )}
                <Title className="font-bold text-3xl md:text-5xl mb-6 font-serif">
                  {heading}
                </Title>

                <div
                  className={'font-normal text-base md:text-xl leading-7 mb-6'}
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>
                <div className="text-center flex justify-center md:justify-start">
                  <CallToAction
                    showCallToAction={showCallToAction}
                    {...callToAction}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </BuilderSection>
  );
};

Builder.registerComponent(Youtube, {
  name: 'Youtube',
  inputs: [
    {
      name: 'isPrimaryView',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'paragraph',
      type: 'richText',
      defaultValue:
        "Recognizing the need for diverse representation in entertainment, Shannon has made it her mission as a storyteller to represent the LGBTQIA+ community through her work. While Shannon still loves creating her own content, she’s recently delved into directing and producing music videos for artists like Joy Oladokun, Alyson Stoner, and Fletcher. She's also produced some stunning narrative shorts and has received several awards for her work. Shannon is a board member for the Love Loud Foundation and hopes to continue her mission to foster an inclusive future for everyone. See more of Shannon's work on her Youtube channel and Instagram.",
    },
    {
      name: 'videoId',
      type: 'string',
      required: true,
      defaultValue: 'JtitcwwEl-A',
    },
    { name: 'darkMode', type: 'boolean', defaultValue: false },
    {
      name: 'imagesFirst',
      type: 'boolean',
      required: true,
      defaultValue: false,
    },
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: '[Heading]',
    },
    {
      name: 'subheading',
      type: 'string',
      defaultValue: 'Create with storyblocks',
    },
    {
      name: 'content',
      type: 'richText',
      defaultValue: '[Subheading]',
      helperText: '320 character limit recommended',
    },
    {
      name: 'backgroundImageSrc',
      required: false,
      type: 'text',
      helperText:
        "Path to background image asset, relative to '/assets/common/images'",
    },
    {
      name: 'backgroundImageSrcAlt',
      type: 'string',
      required: false,
      helperText: 'Background Image alternative text',
    },
    {
      ...lazyLoadDefaultConfig,
    },
    ...callToActionInputs,
  ],
});
