import { Builder } from '@builder.io/react';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import VideoCollectionsCarousel from '../../../Collections/VideoCollectionsCarousel';
import CollectionsAPI from '../../../app/Search/CollectionsAPI';
import { VIDEOBLOCKS } from '../../../common/SiteConstants/SiteConstants';
import { getCurrentSite } from '../../../common/utils';
import headerAlignmentFontInputs, {
  Alignment,
  FontFamily,
} from '../../utils/headerAlignmentFontInputs';
import CallToAction, {
  callToActionFields,
  CallToActionProps,
} from '../elements/CallToAction.builder';
import BuilderSection from '../layouts/BuilderSection';

interface Collections {
  routeURI: string;
}

interface VideoCarouselBuilderProps {
  customContent?: any;
  heading: string;
  subHeading: string;
  newFlag: boolean;
  mediaType: string;
  fontFamily: FontFamily;
  headingAlignment?: Alignment;
  callToAction?: CallToActionProps;
  showHeading?: boolean;
  showCallToAction: boolean;
  collections: Collections[];
}

const millisecondsInASecond = 1000;
const secondsInADay = 86400;

const VideoCarousel = ({
  customContent,
  heading,
  subHeading,
  callToAction,
  showCallToAction,
  collections,
  fontFamily,
  headingAlignment = Alignment.Center,
}: VideoCarouselBuilderProps) => {
  const [collectionCards, setCollectionCards] = useState(customContent || []);

  const getCollections = () => {
    setCollectionCards([]);

    const videoCollections = collections.map((collectionItem) => {
      return collectionItem.routeURI;
    });

    CollectionsAPI.getManyByRouteUris(videoCollections, VIDEOBLOCKS)
      .then((resp) => resp.json())
      .then((resp) => {
        const collectionsArray = resp.data;

        const collectionsDataFormatted = collectionsArray.map(
          (collectionItem) => {
            const dateAdded = collectionItem.dateAdded;
            const utcDateAdded = new Date(dateAdded);

            const getDaysSinceDateAdded = Math.floor(
              Math.abs(Date.parse(utcDateAdded) - new Date()) /
                millisecondsInASecond /
                secondsInADay
            );

            return {
              label: collectionItem.heroTitle,
              href: collectionItem.routeUri,
              thumbnailSrc: collectionItem.directoryBgImgUrl,
              count: collectionItem.totalResults,
              thumbnailAlt: collectionItem.heroTitle,
              showNewLabel: getDaysSinceDateAdded < 100,
            };
          }
        );
        const sortedCollections = videoCollections
          .map((routeUri) =>
            collectionsDataFormatted.find(
              (fetchedItem) => fetchedItem.href === routeUri
            )
          )
          .filter((collectionCard) => collectionCard);
        setCollectionCards(sortedCollections);
      })
      .catch(() => {
        console.error('error fetching collection data');
      });
  };

  const handleFetchCollectionResultsRequest = useDebouncedCallback(
    getCollections,
    350,
    { maxWait: 1000 }
  );

  useEffect(() => {
    if (
      Builder.isEditing ||
      !customContent ||
      getCurrentSite() !== VIDEOBLOCKS
    ) {
      handleFetchCollectionResultsRequest();
    }
  }, [collections]);

  return (
    <BuilderSection
      heading={heading}
      headingClassNames={`${headingAlignment} ${fontFamily} md:text-5xl text-3xl`}
    >
      <>
        <div
          aria-label={subHeading}
          className="md:text-2xl text-xl pl-5 md:pb-6 pb-4 text-left font-bold"
        >
          {subHeading}
        </div>
        <div className="pb-4">
          <VideoCollectionsCarousel collectionCards={collectionCards} />
          {showCallToAction && (
            <div className="p-8">
              <CallToAction
                showCallToAction={showCallToAction}
                {...callToAction}
              />
            </div>
          )}
        </div>
      </>
    </BuilderSection>
  );
};

const collectionCallToActionInputs = [
  {
    name: 'showCallToAction',
    type: 'boolean',
    defaultValue: true,
  },
  {
    name: 'callToAction',
    type: 'object',
    defaultValue: {
      text: 'Explore all collections',
      href: '/',
      color: 'secondary',
      size: 'small',
      variant: 'outlined',
      target: '_self',
      rel: '',
      squared: false,
    },
    showIf: (options) => options.get('showCallToAction'),
    subFields: callToActionFields,
  },
];

Builder.registerComponent(VideoCarousel, {
  name: 'Video Carousel',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: false,
      defaultValue: 'Optional Heading',
    },
    {
      name: 'subHeading',
      type: 'string',
      required: false,
      defaultValue: 'Optional Subheading',
    },
    {
      name: 'collections',
      type: 'list',
      required: true,
      defaultValue: [
        {
          routeURI: 'essentialism-footage',
          text: 'Essentialism Footage',
        },
        {
          routeURI: 'awesome-footage',
          text: 'Awesome Footage',
        },
        {
          routeURI: 'traffic-stock-footage',
          text: 'Traffic Footage Ahead',
        },
      ],
      subFields: [
        {
          name: 'routeURI',
          type: 'string',
        },
      ],
      onChange: (options) => {
        if (options.get('collections').length > 9) {
          options.set('collections', options.get('collections').slice(0, 9));
          alert('Maximum number of collections allowed is 9.');
        }
        if (options.get('collections').length < 3) {
          alert('Minimum number of collections allowed is 3.');
        }
      },
    },
    ...collectionCallToActionInputs,
    ...headerAlignmentFontInputs,
  ],
});
