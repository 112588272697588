import { Builder } from '@builder.io/react';
import React from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import { lazyLoadDefaultConfig } from '../../../constants';
import CallToAction, {
  callToActionInputs,
  CallToActionProps,
} from '../../elements/CallToAction.builder';

interface VideoHeroProps {
  heading: string;
  subheading: string;
  imageSource: string;
  videoSource: string;
  showCallToAction: boolean;
  lazyLoadImage: boolean;
  callToAction?: CallToActionProps;
}

const VideoHero = ({
  heading,
  subheading,
  imageSource,
  videoSource,
  showCallToAction,
  callToAction,
  lazyLoadImage,
}: VideoHeroProps) => {
  const imageSrc = `${__ASSETS_COMMON_IMAGES_URL__}${imageSource}`;
  const videoSrc = `${__ASSETS_COMMON_IMAGES_URL__}${videoSource}`;

  return (
    <header className="py-28 md:mb-6 lg:mb-8 relative w-full px-4 mb-5">
      {videoSource && (
        <video
          autoPlay={true}
          className="lg:block absolute inset-0 hidden object-cover w-full h-full bg-gray-400"
          loop={true}
          muted={true}
          playsInline={true}
          poster={imageSrc}
          preload="auto"
        >
          <source src={videoSrc} type="video/webm" />
          <source src={videoSrc} type="video/mp4" />
          <ProgressiveImage className="w-auto h-auto" src={imageSrc} alt="" />
        </video>
      )}

      <ProgressiveImage
        className="lg:hidden absolute inset-0 object-cover w-full h-full"
        src={imageSrc}
        alt=""
        shouldLazyLoad={lazyLoadImage}
      />
      {/* Opaque dark gray box to make text stand out */}
      <div className="opacity-30 absolute inset-0 w-full h-full bg-gray-900"></div>

      <div className="relative block w-full space-y-6 text-center text-white">
        <h1 className="m-0 text-4xl font-bold">{heading}</h1>

        {subheading && <p className="font-base m-0 text-xl">{subheading}</p>}

        <CallToAction showCallToAction={showCallToAction} {...callToAction} />
      </div>
    </header>
  );
};

Builder.registerComponent(VideoHero, {
  name: 'Video Hero (Legacy)',
  inputs: [
    {
      name: 'heading',
      type: 'longText',
      required: true,
      defaultValue: '[Heading]',
    },
    {
      name: 'subheading',
      type: 'longText',
      defaultValue: '[Subheading]',
    },
    {
      name: 'imageSource',
      type: 'string',
      required: true,
      defaultValue: '/media-page/redesigned-footage/footage-sizzle.jpg',
      helperText: "Path to image asset, relative to '/assets/common/images'",
    },
    {
      name: 'videoSource',
      type: 'string',
      defaultValue: '/media-page/redesigned-footage/footage-sizzle.webm',
      helperText: "Path to image asset, relative to '/assets/common/images'",
    },
    {
      ...lazyLoadDefaultConfig,
    },
    ...callToActionInputs,
  ],
});
