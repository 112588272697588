import React, { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

import ChevronLeft from '@videoblocks/react-icons/ChevronLeft';
import ChevronRight from '@videoblocks/react-icons/ChevronRight';
import { IconButton } from '@videoblocks/storywind';

export enum SlideButtonType {
  NEXT = 'next',
  BACK = 'back',
}
export interface SlideButtonPropTypes {
  type: SlideButtonType;
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
  onClick: () => void;
}

const SlideButton = ({
  type = SlideButtonType.NEXT,
  className,
  onClick,
  disabled,
  ...rest
}: SlideButtonPropTypes): ReactElement => {
  const iconToUse = type === SlideButtonType.NEXT ? ChevronRight : ChevronLeft;

  return (
    <IconButton
      style={{
        boxShadow:
          '0px 4px 10px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.3)',
      }}
      className={twMerge(
        'w-14 h-14 p-5 mx-2 border-none bg-white focus:bg-white',
        className
      )}
      Icon={iconToUse}
      onClick={onClick}
      disabled={disabled}
      aria-label={`Scroll ${type.toLowerCase()}`}
      aria-hidden={true}
      tabIndex={0}
      {...rest}
    />
  );
};

export default SlideButton;
