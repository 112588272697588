import { Builder } from '@builder.io/react';
import React from 'react';

interface ImageHeroProps {
  heading: string;
  subHeading: string;
}

const ImageHero = ({ heading, subHeading }: ImageHeroProps) => (
  <header className="md:mb-6 lg:mb-8 w-full py-16 mb-5 bg-gray-900">
    <div className="relative w-full space-y-6 text-center text-white">
      <h1 className="m-0 text-4xl font-bold">{heading}</h1>

      {subHeading && <p className="font-base m-0 text-xl">{subHeading}</p>}
    </div>
  </header>
);

Builder.registerComponent(ImageHero, {
  name: 'Image Hero',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
    },
    {
      name: 'subHeading',
      type: 'string',
      defaultValue: '',
    },
  ],
});
