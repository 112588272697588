import { Builder } from '@builder.io/react';
import React, { useState, useEffect, useMemo } from 'react';
import cookie from 'react-cookies';

import { SignupSurveyEntEvent } from '@videoblocks/events-ts/lib/storyblocks/user/SignupSurveyEntEvent';
import { Button, ButtonColor, ButtonVariant } from '@videoblocks/storywind';

import events from '../../../Events/Events';
import { lazyLoadDefaultConfig, opacityOptions } from '../../constants';

enum TextColor {
  white = 'text-white',
  gray = 'text-gray-900',
}

interface Opacity {
  percentage: number;
}

interface ThankYouProps {
  heading: string;
  subheading: string;
  useOpaqueBackground: boolean;
  textColor: TextColor;
  opacity?: Opacity;
}

enum HDYHstatus {
  NotSeen,
  Seen,
  Submitted,
  Error,
}

interface ThankYouStateInterface {
  channel: string;
  comment: string;
  status: HDYHstatus;
}

const THANK_YOU_HDYH_COOKIE = 'thankYouHDYH';

const randomisedOptions = () => {
  const options = [
    {
      value: 'searchEngine',
      label: 'Search Engine (Google, Bing, Baidu, Yandex, etc.)',
    },
    {
      value: 'thirdPartyTools',
      label:
        'Third Party Tools (Movavi, Powtoon, LumaFusion, Fiverr, Skillshare, etc.)',
    },
    { value: 'wordOfMouth', label: 'Word of Mouth' },
    {
      value: 'coworker',
      label: 'Coworker who has used/is currently using Storyblocks',
    },
    { value: 'events', label: 'Events (Conference, Seminar, Webinar, etc.)' },
    { value: 'youTube', label: 'YouTube' },
    { value: 'podcast', label: 'Podcast' },
    {
      value: 'publications',
      label: 'Publications (Blog, Report, News Article, etc.)',
    },
    {
      value: 'social',
      label: 'Social (Facebook, Instagram, Reddit, TikTok, Linkedin, etc.)',
    },
    { value: 'email', label: 'Email from Storyblocks' },
  ];

  return options.sort(() => Math.random() - 0.5);
};

const ThankYou = ({
  heading,
  subheading,
  useOpaqueBackground,
  textColor = TextColor.white,
  opacity,
}: ThankYouProps) => {
  const [ThankYouState, setThankYouState] = useState<ThankYouStateInterface>({
    channel: '',
    comment: '',
    status: HDYHstatus.NotSeen,
  });

  const radioOptions = useMemo(() => randomisedOptions(), []);

  useEffect(() => {
    const hasSeenHDYH = cookie.load(THANK_YOU_HDYH_COOKIE);
    const domain = window && window.location && '.' + window.location.hostname;

    if (!hasSeenHDYH)
      cookie.save(THANK_YOU_HDYH_COOKIE, true, { path: '/', domain: domain });
    else setThankYouState({ ...ThankYouState, status: HDYHstatus.Seen });
  }, []);

  const renderRadios = () =>
    radioOptions.map((option) => {
      return (
        <div key={option.value} className="flex flex-row">
          <input
            type="radio"
            name="channel"
            className="m-2"
            id={option.value}
            value={option.value}
            onClick={() =>
              setThankYouState({
                ...ThankYouState,
                channel: option.value,
                comment: '',
              })
            }
          />
          <label className="font-normal mt-1" htmlFor={option.value}>
            {option.label}
          </label>
          <br />
        </div>
      );
    });

  const sendEvent = () => {
    const event = new SignupSurveyEntEvent();

    event.meta = events.meta();
    event.user = events.user();

    event.channel = ThankYouState.channel;
    event.comment = ThankYouState.comment;

    events.produce(event);
    setThankYouState({ ...ThankYouState, status: HDYHstatus.Submitted });
  };

  const SubmitForm = () => {
    if (
      ThankYouState.channel === '' ||
      (ThankYouState.channel === 'other' && ThankYouState.comment === '')
    ) {
      setThankYouState({ ...ThankYouState, status: HDYHstatus.Error });
    } else sendEvent();
  };

  return (
    <header className="py-36 px-6 md:px-4 justify-center flex flex-col items-center relative w-full">
      {useOpaqueBackground && (
        <div
          style={{ opacity: (opacity && opacity.percentage) || 0.45 }}
          className="absolute inset-0 w-full h-full bg-gray-900"
        ></div>
      )}
      <div
        className={`text-center relative inline-block w-full space-y-6 max-w-2xl ${textColor}`}
      >
        <h1 className="w-full m-0 font-bold text-3xl lg:text-5xl">{heading}</h1>
        {subheading && (
          <h2 className="font-base m-0 lg:text-xl text-base">{subheading}</h2>
        )}
      </div>
      {ThankYouState.status !== HDYHstatus.Seen &&
        ThankYouState.status !== HDYHstatus.Submitted && (
          <div className="relative pt-6">
            <div
              className={`text-left font-normal relative inline-block w-full space-y-2 max-w-2xl ${textColor}`}
            >
              <div className="font-semibold">
                How did you hear about us? {'(optional)'}
              </div>
              <div>
                {renderRadios()}
                <input
                  type="radio"
                  name="channel"
                  className="m-2 "
                  id="other"
                  value="other"
                  onClick={() =>
                    setThankYouState({
                      ...ThankYouState,
                      channel: 'other',
                      comment: '',
                    })
                  }
                />
                <label htmlFor="other" className="pr-12 font-normal">
                  Other
                </label>
                {ThankYouState.channel === 'other' && (
                  <input
                    id="comment"
                    name="comment"
                    type="text"
                    className={'text-black w-72 pl-2'}
                    placeholder="Please describe"
                    onChange={(e) =>
                      setThankYouState({
                        ...ThankYouState,
                        comment: e.target.value,
                      })
                    }
                  />
                )}
                <br />
              </div>
              {ThankYouState.status === HDYHstatus.Error && (
                <div className="text-red-200 w-full text-center">
                  {/*for some reason this text red 200 doesnt work :(*/}
                  <h6 style={{ color: '#fecaca' }}>
                    Please complete the required fields
                  </h6>
                </div>
              )}
              <div className="text-center">
                <Button
                  className="text-black no-underline"
                  variant={ButtonVariant.Contained}
                  color={ButtonColor.Light}
                  onClick={SubmitForm}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        )}
      {ThankYouState.status === HDYHstatus.Submitted && (
        <div
          className={`text-center relative inline-block w-full pt-6 space-y-2 max-w-2xl ${textColor}`}
        >
          <h2>Submitted, thanks!</h2>
        </div>
      )}
    </header>
  );
};

Builder.registerComponent(ThankYou, {
  name: 'Thank You',
  inputs: [
    {
      name: 'useOpaqueBackground',
      type: 'boolean',
      required: false,
      defaultValue: true,
    },
    {
      name: 'opacity',
      type: 'object',
      defaultValue: {
        percentage: 0.95,
      },
      showIf: (options) => options.get('useOpaqueBackground'),
      subFields: [
        {
          name: 'percentage',
          type: 'string',
          required: true,
          enum: opacityOptions,
        },
      ],
    },
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Thank You!',
    },
    {
      name: 'subheading',
      type: 'longText',
      required: true,
      defaultValue:
        'Someone from our sales team will reach out to you shortly.',
    },
    {
      ...lazyLoadDefaultConfig,
    },
    {
      name: 'textColor',
      required: true,
      type: 'string',
      enum: [
        {
          label: 'gray',
          value: TextColor.gray,
        },
        {
          label: 'white',
          value: TextColor.white,
        },
      ],
      defaultValue: TextColor.white,
    },
  ],
});
