import { Builder } from '@builder.io/react';
import React from 'react';

import { SfxTag as StorywindSfxTag } from '@videoblocks/storywind';

function SfxTag() {
  return <StorywindSfxTag />;
}

Builder.registerComponent(SfxTag, {
  name: 'Sfx tag',
});
