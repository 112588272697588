import { Builder, withChildren } from '@builder.io/react';
import React from 'react';

import {
  Button as StorywindButton,
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@videoblocks/storywind';
import { ButtonPropTypes } from '@videoblocks/storywind/components/Button';

const Button = withChildren(function Button({
  color,
  fullWidth,
  size,
  squared,
  variant,
  children,
}: ButtonPropTypes) {
  return (
    <StorywindButton
      color={color}
      fullWidth={fullWidth}
      size={size}
      squared={squared}
      variant={variant}
    >
      {children}
    </StorywindButton>
  );
});

Builder.registerComponent(Button, {
  name: 'Button',
  inputs: [
    {
      name: 'color',
      type: 'string',
      defaultValue: ButtonColor.Primary,
      enum: [
        {
          label: 'Primary',
          value: ButtonColor.Primary,
        },
        {
          label: 'Secondary',
          value: ButtonColor.Secondary,
        },
        {
          label: 'Light',
          value: ButtonColor.Light,
        },
        {
          label: 'Pill',
          value: ButtonColor.Pill,
        },
      ],
    },
    {
      name: 'fullWidth',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'size',
      type: 'string',
      defaultValue: ButtonSize.Medium,
      enum: [
        {
          label: 'Small',
          value: ButtonSize.Small,
        },
        {
          label: 'Medium',
          value: ButtonSize.Medium,
        },
        {
          label: 'Large',
          value: ButtonSize.Large,
        },
      ],
    },
    {
      name: 'squared',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'variant',
      type: 'string',
      defaultValue: ButtonVariant.Contained,
      enum: [
        {
          label: 'Contained',
          value: ButtonVariant.Contained,
        },
        {
          label: 'Outlined',
          value: ButtonVariant.Outlined,
        },
      ],
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Text goes here.' } },
    },
  ],
});
