import { Builder } from '@builder.io/react';
import React from 'react';

import CallToAction, {
  CallToActionProps,
  callToActionInputs,
} from '../elements/CallToAction.builder';
import BuilderSection from '../layouts/BuilderSection';

interface FooterHookProps {
  heading: string;
  subheading: string;
  content: string;
  showCallToAction: boolean;
  callToAction?: CallToActionProps;
}

const FooterHook = ({
  heading,
  subheading,
  content,
  showCallToAction,
  callToAction,
}: FooterHookProps) => (
  <BuilderSection bgClassNames="image-background-dark-desktop-only bg-no-repeat bg-contain bg-right text-white">
    <div className="flex md:flex-row w-full">
      <div className="flex flex-col lg:items-start w-full md:w-1/2 lg:my-14 lg:px-24">
        <h2 className="font-semibold text-sm md:text-base uppercase mb-6 mt-0 tracking-widest">
          {subheading}
        </h2>
        <h3 className="font-bold text-3xl md:text-5xl mt-0 mb-6 font-serif">
          {heading}
        </h3>
        <div
          className="w-full max-w-sm text-base mb-6"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
        <div>
          <CallToAction showCallToAction={showCallToAction} {...callToAction} />
        </div>
      </div>
    </div>
  </BuilderSection>
);

Builder.registerComponent(FooterHook, {
  name: 'Footer Hook',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Subscribe to start downloading',
    },
    {
      name: 'subheading',
      type: 'string',
      required: true,
      defaultValue: 'Create With Storyblocks',
    },
    {
      name: 'content',
      type: 'richText',
      required: true,
      defaultValue:
        'Royalty-free and yours to keep forever, all of our content can be downloaded with a subscription',
    },
    ...callToActionInputs,
  ],
});
