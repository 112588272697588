import { Builder } from '@builder.io/react';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import SearchAPI from '../../../app/Search/SearchAPI';
import GraphicStockItemCard from '../../../app/Search/components/card/GraphicStockItemCard';
import {
  getCategoriesForContentType,
  legacyContentTypes,
  orientations,
} from '../../../app/Search/entities/ImageSearchFilterOptions';
import ImageSearchOptions from '../../../app/Search/entities/ImageSearchOptions';
import { SiteEnum } from '../../../common/SiteConstants/SiteConstants';
import {
  MAX_RESULTS_PER_PAGE,
  defaultSortOptions,
  stockItemCardResultOptions,
} from '../../constants';
import headerAlignmentFontInputs, {
  Alignment,
  FontFamily,
} from '../../utils/headerAlignmentFontInputs';
import ExploreLink, {
  exploreLinkInputs,
  ExploreLinkProps,
} from '../elements/ExploreLink.builder';
import BuilderSection from '../layouts/BuilderSection';
import StockItemCardLoader from '../loading-skeletons/StockItemCardLoader';

interface ImageContentGridProps {
  category: string;
  color: string;
  hasTransparency: boolean;
  heading: string;
  mediaType: string;
  orientation: string;
  numResultsMobile: string;
  numResults: string;
  searchTerm: string;
  sort: string;
  showExploreLink: boolean;
  exploreLink?: ExploreLinkProps;
  fontFamily: FontFamily;
  headingAlignment: Alignment;
}

const ImageContentGrid = ({
  category,
  color,
  hasTransparency,
  heading,
  mediaType,
  orientation,
  numResultsMobile,
  numResults,
  searchTerm,
  sort,
  showExploreLink,
  exploreLink,
  fontFamily,
  headingAlignment,
}: ImageContentGridProps) => {
  const [content, setContent] = useState([]);

  const mobileNumResults = parseInt(numResultsMobile, 10);
  const desktopNumResults = parseInt(numResults, 10);

  const resultsToFetch = Builder.isEditing
    ? MAX_RESULTS_PER_PAGE
    : Math.min(
        MAX_RESULTS_PER_PAGE,
        Math.max(desktopNumResults, mobileNumResults)
      );

  const getSearchResults = () => {
    setContent([]);

    /** NOTE: object keys should match object keys in ImageMenuFilters.js */
    const searchParameters = new ImageSearchOptions().buildSearchApiParameters({
      categories: category,
      color,
      contentType: mediaType,
      hasTransparency,
      orientation,
      resultsPerPage: resultsToFetch,
      searchOrigin: Builder.isEditing
        ? 'builder_editing'
        : 'builder-image-content-grid',
      searchTerm,
      sort,
    });

    SearchAPI.searchBuilder(SiteEnum.Graphicstock, searchParameters)
      .then((resp) => resp.json())
      .then((resp) => {
        setContent(resp?.data?.stockItems || []);
      });
  };

  const handleFetchSearchResultsRequest = useDebouncedCallback(
    getSearchResults,
    350,
    { maxWait: 1000 }
  );

  useEffect(() => {
    handleFetchSearchResultsRequest();
  }, [
    category,
    color,
    hasTransparency,
    mediaType,
    orientation,
    searchTerm,
    sort,
  ]);

  return (
    <BuilderSection
      heading={heading}
      headingClassNames={`${headingAlignment} ${fontFamily} text-5xl text-gray-900`}
    >
      <div className="w-full space-y-6">
        <div className="stock-item-group-wrapper card-design">
          {content?.length < 1 ? (
            <div className="grid grid-cols-1 gap-4">
              <div className="md:grid-cols-3 grid grid-cols-1 gap-4">
                {[...Array(mobileNumResults)].map((_, index) => (
                  <StockItemCardLoader key={`image-card-loader-${index}`} />
                ))}
              </div>
              <div className="md:grid md:grid-cols-3 md:gap-4 hidden">
                {[
                  ...Array(Math.max(0, desktopNumResults - mobileNumResults)),
                ].map((_, index) => (
                  <StockItemCardLoader
                    key={`image-card-loader-${numResultsMobile + index}`}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="stock-item-v2 grid grid-cols-1 gap-4">
              <div className="md:grid-cols-3 grid grid-cols-1 gap-4">
                {content.slice(0, mobileNumResults).map((item, index) => (
                  <GraphicStockItemCard
                    {...item}
                    containerResponsiveClassNames={[]}
                    classNames={['m-0', 'p-0']}
                    imgClassNames={['w-full', 'h-full', 'object-cover']}
                    imgWrapperClassNames={[
                      'aspect-w-16',
                      'aspect-h-9',
                      'rounded-lg',
                      'overflow-hidden',
                    ]}
                    key={`image-stock-item-card-${item.id || index}`}
                    shouldShowAddToFavorites
                  />
                ))}
              </div>
              <div className="md:grid md:grid-cols-3 md:gap-4 hidden">
                {content
                  .slice(mobileNumResults, desktopNumResults)
                  .map((item, index) => (
                    <GraphicStockItemCard
                      {...item}
                      containerResponsiveClassNames={[]}
                      classNames={['m-0', 'p-0']}
                      imgClassNames={['w-full', 'h-full', 'object-cover']}
                      imgWrapperClassNames={[
                        'aspect-w-16',
                        'aspect-h-9',
                        'rounded-lg',
                        'overflow-hidden',
                      ]}
                      key={`image-stock-item-card-${
                        item.id || mobileNumResults + index
                      }`}
                      shouldShowAddToFavorites
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
        <ExploreLink showExploreLink={showExploreLink} {...exploreLink} />
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(ImageContentGrid, {
  name: 'Image Content Grid',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Browse Images',
    },
    {
      name: 'numResultsMobile',
      friendlyName: 'Number of Results (mobile)',
      type: 'string',
      enum: stockItemCardResultOptions,
      defaultValue: '3',
      required: true,
    },
    {
      name: 'numResults',
      friendlyName: 'Number of Results',
      type: 'string',
      enum: stockItemCardResultOptions,
      defaultValue: '9',
      required: true,
    },
    {
      name: 'mediaType',
      type: 'string',
      required: true,
      enum: legacyContentTypes,
      defaultValue: 'all-images',
    },
    {
      name: 'searchTerm',
      type: 'string',
      defaultValue: '',
    },
    {
      name: 'orientation',
      type: 'string',
      enum: orientations,
      defaultValue: 'all',
    },
    {
      name: 'color',
      type: 'string',
      defaultValue: '',
      helperText: 'A 3 or 6-character HEX color value',
      regex: {
        pattern: '^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$',
        options: 'g',
        message: 'Must be a valid 3 or 6 character HEX value.',
      },
    },
    {
      name: 'category',
      type: 'string',
      showIf: (options) => options.get('mediaType') === 'all-images',
      enum: getCategoriesForContentType('all-images'),
      defaultValue: '',
    },
    {
      name: 'category',
      type: 'string',
      showIf: (options) => options.get('mediaType') === 'photos',
      enum: getCategoriesForContentType('photos'),
      defaultValue: '',
    },
    {
      name: 'category',
      type: 'string',
      showIf: (options) => options.get('mediaType') === 'vectors',
      enum: getCategoriesForContentType('vectors'),
      defaultValue: '',
    },
    {
      name: 'category',
      type: 'string',
      showIf: (options) => options.get('mediaType') === 'illustrations',
      enum: getCategoriesForContentType('illustrations'),
      defaultValue: '',
    },
    {
      name: 'hasTransparency',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'sort',
      type: 'string',
      defaultValue: 'most_relevant',
      required: true,
      enum: [...defaultSortOptions],
    },
    ...headerAlignmentFontInputs,
    ...exploreLinkInputs,
  ],
});
