import { Builder } from '@builder.io/react';
import React, { useState, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import ChevronLeftIcon from '@videoblocks/react-icons/ChevronLeft';
import ChevronRightIcon from '@videoblocks/react-icons/ChevronRight';

import headerAlignmentFontInputs, {
  Alignment,
  FontFamily,
} from '../../utils/headerAlignmentFontInputs';
import BuilderSection from '../layouts/BuilderSection';

export interface ContentCarouselTab {
  tabVideoSrc: string;
  tabHeader: string;
  tabParagraph: string;
  tabUrlName: string;
  tabUrl: string;
}

interface ContentCarouselProps {
  heading: string;
  backgroundColor: string;
  subHeading: string;
  headingAlignment: Alignment;
  fontFamily: FontFamily;
  contentCarouselTabs: ContentCarouselTab[];
}

export const ContentCarousel = ({
  heading,
  backgroundColor,
  subHeading,
  headingAlignment,
  fontFamily,
  contentCarouselTabs,
}: ContentCarouselProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [indicatorPosition, setIndicatorPosition] = useState(0);

  const indicatorWidth = 100 / contentCarouselTabs.length;

  useEffect(() => {
    setIndicatorPosition(activeTabIndex * indicatorWidth);
  }, [activeTabIndex, contentCarouselTabs]);

  const handleTabChange = (newIndex: number) => {
    setActiveTabIndex(newIndex);
    const indicatorWidth = 100 / contentCarouselTabs.length;
    const newPosition = newIndex * indicatorWidth;
    setIndicatorPosition(newPosition);
  };

  return (
    <BuilderSection
      className={`${headingAlignment} w-96 mb-4 text-canter lg:mb-0 lg:w-full lg:block`}
      heading={heading}
      subheading={subHeading}
      headingClassNames={`${fontFamily} m-0 text-4xl text-gray-900`}
      bgClassNames={`bg-${backgroundColor}`}
    >
      {/*Video content Carousel prop & arrows section */}
      <div className="relative">
        <div className="flex flex-row items-center justify-center h-48 lg:h-144">
          <ChevronLeftIcon
            className="hidden lg:block w-12 h-12 lg:mr-14"
            onClick={() =>
              handleTabChange(
                activeTabIndex === 0
                  ? contentCarouselTabs.length - 1
                  : activeTabIndex - 1
              )
            }
          />
          <div className="flex items-center justify-center rounded-2xl shadow-lg bg-white w-80 h-full lg:w-full">
            {contentCarouselTabs[activeTabIndex].tabVideoSrc ? (
              <video
                className="rounded-2xl w-80 h-full object-cover ppro-sm:w-full"
                src={contentCarouselTabs[activeTabIndex].tabVideoSrc}
                loop
                muted
                autoPlay
              />
            ) : (
              <p className="text-center">No video available</p>
            )}
          </div>
          <ChevronRightIcon
            className="hidden lg:block w-12- h-12 lg:ml-14"
            onClick={() =>
              handleTabChange(
                activeTabIndex === contentCarouselTabs.length - 1
                  ? 0
                  : activeTabIndex + 1
              )
            }
          />
        </div>
      </div>

      {/*Desktop Blue indicator*/}
      <div className="hidden lg:block bg-gray-300 w-1248 h-1 mt-12 flex-shrink-0">
        <div
          className="bg-blue-700 h-full"
          style={{
            width: `${indicatorWidth}%`,
            marginLeft: `${indicatorPosition}%`,
          }}
        ></div>
      </div>

      {/*Desktop content carousel prop tabs*/}
      <div className="hidden lg:flex space-x-20 cursor-pointer">
        {contentCarouselTabs.map((tab, index) => (
          <div
            key={index}
            className={twMerge(
              'flex-1',
              index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
              index === contentCarouselTabs.length - 1
                ? 'rounded-bl-md rounded-br-md'
                : ''
            )}
            role="button"
            tabIndex={0}
            onClick={() => handleTabChange(index)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleTabChange(index);
              }
            }}
          >
            <h2 className="text-gray-900 font-sans text-lg font-bold leading-8 text-left lg:w-343 lg:h-85">
              {tab.tabHeader}
            </h2>
            <p className="text-gray-900 font-sans text-base font-normal leading-6 text-left w-60">
              {tab.tabParagraph}
            </p>
            <a
              href={tab.tabUrl}
              className="block text-gray-900 font-sans text-base font-semibold leading-6 underline text-left"
            >
              {tab.tabUrlName}
            </a>
          </div>
        ))}
      </div>

      {/*Mobile content carousel prop tabs*/}
      <div className="flex justify-center">
        <div className="lg:hidden text-blue-900 mt-3 rounded-lg border-solid border-2 border-gray-400 divide-solid divide-y divide-gray-400">
          {contentCarouselTabs.map((tab, index) => (
            <div
              key={index}
              className={twMerge(
                'pt-0 border-r-0 border-l-0',
                index === activeTabIndex ? 'bg-blue-100' : '',
                index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                index === contentCarouselTabs.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : ''
              )}
            >
              <button
                className="flex items-center justify-center border-none w-80 text-center pb-4 focus:outline-none"
                onClick={() => {
                  setActiveTabIndex(index);
                }}
                aria-expanded={index === activeTabIndex}
              >
                <span className="pt-4 font-sans font-bold text-lg leading-7">
                  {tab.tabHeader}
                </span>
              </button>
              {index === activeTabIndex && (
                <div className="text-center font-sans text-base font-normal leading-5">
                  <p className="w-60 mx-auto">{tab.tabParagraph}</p>
                  <a
                    href={tab.tabUrl}
                    className="flex justify-center items-center pb-4 text-base font-sans font-semibold underline"
                  >
                    {tab.tabUrlName}
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(ContentCarousel, {
  name: 'Content Carousel',
  inputs: [
    {
      name: 'backgroundColor',
      type: 'string',
      defaultValue: 'gray-100',
      helperText: 'Specify a tailwind color',
    },
    {
      name: 'heading',
      type: 'string',
      required: false,
      defaultValue: 'Streamline your creative process',
    },
    {
      name: 'subHeading',
      type: 'string',
      required: false,
      defaultValue: ' ',
    },
    {
      name: 'contentCarouselTabs',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'tabVideoSrc',
          type: 'file',
          allowedFileTypes: ['webm', 'mp4'],
          defaultValue: '/home/video-homepage-hero-background.webm',
          helperText: 'upload video file',
        },
        {
          name: 'tabHeader',
          type: 'string',
          required: true,
          defaultValue: 'Extensive media library',
          helperText: 'Enter the header for the tab',
        },
        {
          name: 'tabParagraph',
          type: 'string',

          required: true,
          defaultValue:
            'Video, Templates, Audio, and Images for all your creative needs',
          helperText: 'Enter the paragraph for the tab',
        },
        {
          name: 'tabUrlName',
          type: 'string',
          required: true,
          defaultValue: 'Explore library',
          helperText: 'Enter the text for the tab url',
        },
        {
          name: 'tabUrl',
          type: 'string',
          required: true,
          defaultValue: '/',
          helperText: 'Enter the url for the tab',
        },
      ],
      onChange: (options) => {
        const maxTabs = 4;
        if (options.get('contentCarouselTabs').length > maxTabs) {
          options.set(
            'contentCarouselTabs',
            options.get('contentCarouselTabs').slice(0, maxTabs)
          );
          alert('Maximum number of tabs is 4. Modify an existing tab instead.');
        }
      },
      defaultValue: [
        {
          tabVideoSrc: null,
          tabHeader: 'Extensive media library',
          tabParagraph:
            'Video, Templates, Audio, and Images for all your creative needs',
          tabUrlName: 'Explore library',
          tabUrl: '',
        },
        {
          tabVideoSrc: null,
          tabHeader: 'Integrate with Adobe',
          tabParagraph:
            'Access Storyblocks directly in Premiere Pro and After Effects',
          tabUrlName: 'Learn more',
          tabUrl: '',
        },
        {
          tabVideoSrc: null,
          tabHeader: 'Organize your assets',
          tabParagraph:
            'Streamline your creative process by organizing all your assets',
          tabUrlName: 'Create free account',
          tabUrl: '',
        },
        {
          tabVideoSrc: null,
          tabHeader: 'Make a video',
          tabParagraph: 'Create short-form video with our online video editor',
          tabUrlName: 'Explore Maker',
          tabUrl: '',
        },
      ],
    },
    ...headerAlignmentFontInputs,
  ],
});
