import { Builder } from '@builder.io/react';
import React from 'react';

import ChiliPiperLoader from '@videoblocks/shared-components/dist/mjs/components/Shared/ChiliPiperLoader';
import HubspotForm from '@videoblocks/shared-components/dist/mjs/components/Shared/HubspotForm';

const Hubspot = ({ formId, loadingID, includeChiliPiper }) => (
  <>
    <HubspotForm formId={formId} loadingID={loadingID} />
    {includeChiliPiper && <ChiliPiperLoader />}
  </>
);

Builder.registerComponent(Hubspot, {
  name: 'Hubspot',
  hideFromInsertMenu: true,
  inputs: [
    {
      name: 'formId',
      type: 'string',
      required: true,
      defaultValue: 'c3514b58-be2d-415e-9fd0-51866219148c',
    },
  ],
});

Builder.registerComponent(Hubspot, {
  name: 'v2:Hubspot',
  inputs: [
    {
      name: 'formId',
      type: 'string',
      required: true,
      defaultValue: 'c3514b58-be2d-415e-9fd0-51866219148c',
    },
    {
      name: 'loadingID',
      type: 'string',
      required: true,
      defaultValue: 'hubspot-form',
      helperText:
        'Change this value if there are 2 hubspot forms on the same page',
    },
    {
      name: 'includeChiliPiper',
      type: 'boolean',
      required: true,
      defaultValue: false,
    },
  ],
});
