import { Builder } from '@builder.io/react';
import React, { ReactElement } from 'react';

import Logos, { LogosType } from '../../../Enterprise/components/Logos';
import BuilderSection from '../layouts/BuilderSection';

interface LogosComponentProps {
  logos: LogosType[];
  heading: string;
  numLogosShown: number;
}

const LogoComponentBuilder = ({
  heading,
  numLogosShown,
  logos,
}: LogosComponentProps): ReactElement => (
  <BuilderSection
    heading={heading}
    headingClassNames="text-center font-serif text-5xl text-gray-900"
  >
    <Logos logosArr={logos} numLogosShown={numLogosShown} />
  </BuilderSection>
);

Builder.registerComponent(LogoComponentBuilder, {
  name: 'Logos Component',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Trusted by Companies',
    },
    {
      name: 'numLogosShown',
      friendlyName: 'Number of Logos to show',
      type: 'string',
      enum: ['4', '6', '8'],
      required: true,
      defaultValue: '8',
    },

    {
      name: 'logos',
      type: 'list',
      required: true,
      defaultValue: [
        {
          imageIndustryLogo: '/logos/partners/the-washington-post.svg',
          altText: 'The Washington Post',
        },
        {
          imageIndustryLogo: '/logos/partners/canon.svg',
          altText: 'Canon',
        },
        {
          imageIndustryLogo: '/logos/partners/buzzfeed.svg',
          altText: 'Buzzfeed',
        },
        {
          imageIndustryLogo: '/logos/partners/ogilvy.svg',
          altText: 'Ogilvy',
        },
        {
          imageIndustryLogo: '/logos/partners/nbc-universal.svg',
          altText: 'NBC Universal',
        },
        {
          imageIndustryLogo: '/logos/partners/harpercollins.svg',
          altText: 'Harper Collins',
        },
        {
          imageIndustryLogo: '/logos/partners/univision.svg',
          altText: 'Univison',
        },
        {
          imageIndustryLogo: '/logos/partners/boston-consulting-group.svg',
          altText: 'Boston Consulting Group',
        },
      ],
      onChange: (options) => {
        if (options.get('logos').length > 8) {
          options.set('logos', options.get('logos').slice(0));
          alert(
            'Maximum number of logos is 8. Modify an existing cards instead.'
          );
        }
      },
      subFields: [
        { name: 'imageIndustryLogo', type: 'string', required: true },
      ],
    },
  ],
});
