import { Builder } from '@builder.io/react';
import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import ChevronDown from '@videoblocks/react-icons/ChevronDown';

import SiteConstants from '../../../common/SiteConstants/SiteConstants';
import BuilderSection from '../layouts/BuilderSection';

interface FAQItem {
  question: string;
  answer: string;
}

interface FAQProps {
  faqItems: FAQItem[];
  showViewAllFAQButton: boolean;
}

const FAQ = ({ faqItems, showViewAllFAQButton }: FAQProps) => {
  const [openedSection, setOpenedSection] = useState('');
  const sections = faqItems.map((item) => ({
    sectionName: item.question,
    text: item.answer,
  }));

  const isAnOpenSection = (sectionName) => {
    return openedSection === sectionName;
  };

  const toggleSection = (sectionName) => {
    setOpenedSection(isAnOpenSection(sectionName) ? '' : sectionName);
  };

  return (
    <BuilderSection
      headingClassNames="text-gray-900 text-5xl font-serif text-center mb-2.5"
      heading="Frequently Asked Questions"
    >
      <div className="max-w-3xl self-center w-full flex flex-col items-center">
        <ul className="w-full">
          {sections.map((section) => {
            const { sectionName, text } = section;
            const isOpen = isAnOpenSection(sectionName);
            return (
              <li
                key={`faqItem-${sectionName}`}
                id={`faqItem-${sectionName}`}
                className="list-none border-t-0 border-r-0 border-l-0 border-b border-solid border-gray-300 p-4 cursor-pointer"
                onClickCapture={() => toggleSection(sectionName)}
              >
                <div className="flex justify-between items-center">
                  <h3
                    className={twMerge(
                      'text-lg text-gray-900',
                      isOpen && 'text-blue-500'
                    )}
                  >
                    {sectionName}
                  </h3>
                  <div className="h-4 w-4">
                    <ChevronDown
                      className={twMerge(
                        'min-h-full min-w-full transition duration-200 ease-in',
                        isOpen && 'transform -rotate-180'
                      )}
                    />
                  </div>
                </div>
                <div
                  className={twMerge(isOpen ? 'block' : 'hidden')}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </li>
            );
          })}
        </ul>
        {/* TODO: use Button from @videoblocks/storywind once v3.0.0 is released  */}
        {showViewAllFAQButton && (
          <a
            href={SiteConstants.getIntercomHelpCenterUrl()}
            className="focus:outline-none focus-visible:ring focus-visible:ring-opacity-20 disabled:text-gray-500 font-semibold border-2 border-transparent border-solid transition-colors disabled:bg-gray-200 hover:bg-gray-900 focus:bg-gray-900 text-white bg-black focus-visible:ring-gray-500 px-8 py-3 leading-6 rounded-full text-base w-52 text-center mt-9"
            target="_blank"
          >
            See all FAQs
          </a>
        )}
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(FAQ, {
  name: 'FAQ',
  inputs: [
    {
      name: 'faqItems',
      type: 'list',
      required: true,
      defaultValue: [{ question: 'Question', answer: 'Answer' }],
      subFields: [
        {
          name: 'question',
          type: 'string',
          required: true,
          defaultValue: 'Question',
        },
        {
          name: 'answer',
          type: 'richText',
          required: true,
          defaultValue: 'Answer',
        },
      ],
    },
    {
      name: 'showViewAllFAQButton',
      type: 'boolean',
      defaultValue: true,
    },
  ],
});
