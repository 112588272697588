import React from 'react';

import { getRandomWidth } from './utils';

const AudioStockItemCardLoader = () => (
  <div className="bg-gray-50 lg:flex-row lg:h-24 shadow-plan-card flex flex-col items-center gap-4 p-4">
    <div className="flex-nowrap gap-x-4 lg:w-1/3 flex items-center w-full">
      {/* Top / Left container */}
      <div className="animate-pulse w-14 h-14 bg-blue-200 rounded-full">
        {/* Play / Pause Button */}
      </div>
      <div className="flex-grow space-y-2">
        {/* Title, Artist, Tags */}
        <div
          className={`${getRandomWidth()} h-3 animate-pulse bg-blue-200 rounded-xl`}
        ></div>
        <div
          className={`${getRandomWidth()} h-3 animate-pulse bg-blue-200 rounded-xl`}
        ></div>
        <div
          className={`${getRandomWidth()} h-3 animate-pulse bg-blue-200 rounded-xl`}
        ></div>
      </div>
    </div>

    <div className="lg:gap-6 flex-nowrap lg:w-2/3 flex items-center w-full gap-4">
      {/* Bottom / Right container */}
      <div className="flex-grow h-12 bg-blue-100 rounded-md">
        {/* Waveform */}
      </div>
      <div className="flex-nowrap lg:gap-6 flex items-center h-full gap-4">
        {/* Duration & Buttons */}
        <div className="animate-pulse w-10 h-5 bg-blue-200 rounded-lg"></div>
        <div className="animate-pulse w-6 h-6 bg-blue-200 rounded-lg"></div>
        <div className="animate-pulse w-6 h-6 bg-blue-200 rounded-lg"></div>
        <div className="animate-pulse w-6 h-6 bg-blue-200 rounded-lg"></div>
      </div>
    </div>
  </div>
);

export default AudioStockItemCardLoader;
