import { Builder } from '@builder.io/react';
import React from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import { lazyLoadDefaultConfig } from '../../constants';
import BuilderSection from '../layouts/BuilderSection';

interface FeaturedArtist {
  href: string;
  text: string;
  imageSource: string;
}

interface FeaturedArtistsProps {
  heading: string;
  featuredArtists: FeaturedArtist[];
  lazyLoadImage: boolean;
}

const FeaturedArtists = ({
  heading,
  featuredArtists,
  lazyLoadImage,
}: FeaturedArtistsProps) => {
  return (
    <BuilderSection heading={heading} className="w-full">
      <div className="grid grid-flow-col items-center gap-x-6 gap-y-4 overflow-x-auto">
        {featuredArtists.map(({ href, text, imageSource }) => (
          <div key={text} className="group flex-column">
            <a
              className="text-gray-700 text-center no-underline cursor-pointer space-y-4"
              href={href}
            >
              <div className="overflow-hidden h-33 w-33 rounded-full safari-rounded-border inline-block relative">
                <ProgressiveImage
                  className="h-auto w-full block transform group-hover:scale-110 transition duration-300"
                  alt={text}
                  shouldLazyLoad={lazyLoadImage}
                  src={`${__ASSETS_COMMON_IMAGES_URL__}${imageSource}`}
                />
              </div>
              <p className="group-hover:text-gray-900">{text}</p>
            </a>
          </div>
        ))}
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(FeaturedArtists, {
  name: 'Featured Artists',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Featured Artists',
    },
    {
      name: 'featuredArtists',
      type: 'list',
      required: true,
      defaultValue: [
        {
          href: '/audio/search?media-type=music&portal_artist_ids=BNgaSVTqoUkao4zccm',
          text: 'Amber Waldron',
          imageSource: '/featured-artists/amberwaldron.jpg',
          lazyLoadImage: true,
        },
        {
          href: '/audio/search?media-type=music&portal_artist_ids=SHliGr4jiUkaom6ayg',
          text: 'Jontavious Willis',
          imageSource: '/featured-artists/jontaviouswillis.jpg',
          lazyLoadImage: true,
        },
        {
          href: '/audio/search?media-type=music&portal_artist_ids=S4b7cm-iukpsnrczk',
          text: 'Kaii Dreams',
          imageSource: '/featured-artists/kaiidreams.jpg',
          lazyLoadImage: true,
        },
        {
          href: '/audio/search?portal_artist_ids=Hsx8DES0Jvkcpaqoyq',
          text: 'Q-Rock',
          imageSource: '/featured-artists/qrock.jpg',
          lazyLoadImage: true,
        },
        {
          href: '/audio/search?portal_artist_ids=rZYWIHMLUk80843mh',
          text: 'Burning Heart',
          imageSource: '/featured-artists/burningheart.png',
          lazyLoadImage: true,
        },
        {
          href: '/audio/search?media-type=music&portal_artist_ids=r--G8Hf8Uk80849sm',
          text: 'LiQWYD',
          imageSource: '/featured-artists/liqwyd.jpg',
          lazyLoadImage: true,
        },
        {
          href: '/audio/search?media-type=music&portal_artist_ids=rf7MlROIdknm8pfa7',
          text: 'Matt Oakley',
          imageSource: '/featured-artists/mattoakley.png',
          lazyLoadImage: true,
        },
        {
          href: '/audio/search?media-type=music&portal_artist_ids=HK9JdXbjukpsnf7fy',
          text: 'Nite Owl',
          imageSource: '/featured-artists/nite_owl_2.png',
          lazyLoadImage: true,
        },
      ],
      subFields: [
        {
          name: 'href',
          type: 'string',
          required: true,
          defaultValue: '/',
        },
        {
          name: 'text',
          type: 'string',
          required: true,
          defaultValue: 'Artists',
        },
        {
          name: 'imageSource',
          type: 'string',
          required: true,
          defaultValue: '/featured-artists/',
        },
        {
          ...lazyLoadDefaultConfig,
        },
      ],
      onChange: (options) => {
        if (options.get('featuredArtists').length > 8) {
          options.set(
            'featuredArtists',
            options.get('featuredArtists').slice(0, 8)
          );
          alert(
            'Maximum number of featured artists is 8. Modify an existing featured artist instead.'
          );
        }
      },
    },
  ],
});
