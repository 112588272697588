export const getBackgroundStyles = (darkMode, backgroundSource) => {
  let result = '';
  if (darkMode && (backgroundSource == '' || !backgroundSource)) {
    result = 'bg-gray-950 ';
  }
  if (darkMode) {
    result += 'text-white';
  } else {
    result += 'text-gray-900';
  }
  return result;
};
