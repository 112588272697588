import { Builder } from '@builder.io/react';
import React from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import { lazyLoadDefaultConfig } from '../../constants';
import CallToAction, {
  CallToActionProps,
  callToActionInputs,
} from '../elements/CallToAction.builder';
import BuilderSection from '../layouts/BuilderSection';

interface FeatureBullet {
  icon: string;
  text: string;
}

interface ImageAndTextProps {
  darkMode: boolean;
  imagesFirst: boolean;
  heading: string;
  subheading?: string;
  featureBullets: FeatureBullet[];
  content: string;
  imageSource: string;
  imageSourceAlt?: string;
  lazyLoadImage: boolean;
  videoSource?: string;
  showCallToAction: boolean;
  callToAction?: CallToActionProps;
}

function headerValue(subheading: string) {
  if (subheading) {
    return 'h3';
  }

  return 'h2';
}

const ImageAndText = ({
  darkMode,
  imagesFirst,
  heading,
  subheading,
  featureBullets,
  content,
  imageSource,
  imageSourceAlt,
  showCallToAction,
  lazyLoadImage,
  callToAction,
  videoSource,
}: ImageAndTextProps) => {
  const imageSrc = `${__ASSETS_COMMON_IMAGES_URL__}${imageSource}`;
  const videoSrc = `${__ASSETS_COMMON_IMAGES_URL__}${videoSource}`;
  const Title = headerValue(subheading);

  return (
    <BuilderSection
      className="flex justify-center"
      bgClassNames={`${darkMode ? 'bg-gray-950 text-white' : 'text-gray-900'}`}
    >
      <div className="flex-nowrap px-6 md:px-24 py-8 md:py-10 w-full flex flex-col md:flex-row items-center gap-8 justify-start">
        <div
          className={`${
            imagesFirst ? 'md:order-1' : 'md:order-2'
          } md:w-1/2 w-full`}
        >
          <div className="overflow-hidden">
            {videoSource && (
              <div className="lg:block hidden aspect-w-16 aspect-h-9 rounded-3xl">
                <video
                  autoPlay={true}
                  className="inset-0 object-cover rounded-2.5xl w-full h-full bg-gray-400"
                  loop={true}
                  muted={true}
                  playsInline={true}
                  poster={imageSrc}
                  preload="auto"
                >
                  <source src={videoSrc} type="video/webm" />
                  <source src={videoSrc} type="video/mp4" />
                  <ProgressiveImage
                    src={imageSrc}
                    alt={imageSourceAlt}
                    shouldLazyLoad={lazyLoadImage}
                  />
                </video>
              </div>
            )}
            <ProgressiveImage
              className={videoSource ? 'lg:hidden' : ''}
              src={imageSrc}
              alt={imageSourceAlt}
              shouldLazyLoad={lazyLoadImage}
            />
          </div>
        </div>

        <div
          className={`${
            imagesFirst ? 'md:order-2' : 'md:order-1'
          } md:w-1/2 w-full`}
        >
          {subheading && (
            <h2 className="font-semibold text-sm md:text-base uppercase mb-6 tracking-widest">
              {subheading}
            </h2>
          )}
          <Title className="font-bold text-3xl md:text-5xl mb-6 font-serif">
            {heading}
          </Title>

          <ul>
            {featureBullets?.length > 0 &&
              featureBullets.map((bullet) => (
                <li className="flex flex-row items-center justify-content mb-4 md:mb-6">
                  <ProgressiveImage
                    className="fill-current text-indigo-600"
                    src={`${__ASSETS_COMMON_IMAGES_URL__}${bullet.icon}_${
                      darkMode ? 'white' : 'black'
                    }.svg`}
                    alt=""
                    height="24"
                    width="24"
                  />
                  <div className="pl-2 font-bold text-base md:text-xl">
                    {bullet.text}
                  </div>
                </li>
              ))}
          </ul>

          <div
            className={'font-normal text-base md:text-xl leading-7 mb-6'}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          <div className="text-center flex justify-center md:justify-start">
            <CallToAction
              showCallToAction={showCallToAction}
              {...callToAction}
            />
          </div>
        </div>
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(ImageAndText, {
  name: 'Image And Text',
  inputs: [
    {
      name: 'darkMode',
      type: 'boolean',
      required: true,
      defaultValue: false,
    },
    {
      name: 'imagesFirst',
      type: 'boolean',
      required: true,
      defaultValue: false,
    },
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: '[Heading]',
    },
    {
      name: 'subheading',
      type: 'string',
      defaultValue: 'Create with storyblocks',
    },
    {
      name: 'featureBullets',
      type: 'list',
      required: false,
      defaultValue: [{ icon: '/icons/video_icon', text: 'Cool feature!' }],
      subFields: [
        {
          name: 'icon',
          type: 'string',
          required: true,
          defaultValue: '/icons/video_icon',
          helperText:
            "text '_black.svg' or '_white.svg' will be appended automatically at the end of icon source for dark/white mode",
        },
        {
          name: 'text',
          type: 'string',
          required: true,
          defaultValue: 'Cool feature!',
        },
      ],
      onChange: (options) => {
        if (options.get('featureBullets').length > 4) {
          options.set(
            'featureBullets',
            options.get('featureBullets').slice(0, 4)
          );
          alert(
            'Maximum number of featured bullets is 4. Modify an existing featured bullet instead.'
          );
        }
      },
    },
    {
      name: 'content',
      type: 'richText',
      defaultValue: `[Subheading]`,
      helperText: '320 character limit recommended',
    },
    {
      name: 'imageSource',
      type: 'string',
      required: true,
      defaultValue: '/featured-artists/liqwyd.jpg',
      helperText: "Path to image asset, relative to '/assets/common/images'",
    },
    {
      name: 'imageSourceAlt',
      type: 'string',
      helperText: 'Image alternative text',
    },
    {
      name: 'videoSource',
      type: 'string',
      helperText: "Path to video asset, relative to '/assets/common/images'",
    },
    {
      ...lazyLoadDefaultConfig,
    },
    ...callToActionInputs,
  ],
});
