/**
 * colors.js creates an array of tailwind classes
 * that map to colors defined in tailwind.config.js
 */
import * as twConfigs from '../../../../tailwind.config';

const colorOptions = Object.entries(twConfigs.theme.extend.colors).reduce(
  (allColorOptions, currentColorOptions) => {
    const [color, shades] = currentColorOptions;

    if (color !== 'old') {
      const colorShadeOptions = Object.keys(shades).map(
        (shade) => `${color}-${shade}`
      );
      return allColorOptions.concat(colorShadeOptions);
    }
    return allColorOptions;
  },
  []
);

const backgroundColorOptions = colorOptions.map(
  (colorOption) => `bg-${colorOption}`
);
const textColorOptions = colorOptions.map(
  (colorOption) => `text-${colorOption}`
);

export { backgroundColorOptions, textColorOptions };
