import { Builder } from '@builder.io/react';
import React from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import { textColorOptions } from '../../../storyblocks-design-system/colors';
import CallToAction, {
  CallToActionProps,
  callToActionInputs,
} from '../../elements/CallToAction.builder';
import BuilderSection from '../../layouts/BuilderSection';

interface MediaAndTextProps {
  heading: string;
  headingColor: string;
  content: string;
  imageSource: string;
  videoSource?: string;
  mediaFirst?: boolean;
  lazyLoad: boolean;
  showCallToAction: boolean;
  callToAction?: CallToActionProps;
}

const MediaAndText = ({
  heading,
  headingColor,
  content,
  imageSource,
  videoSource,
  mediaFirst,
  lazyLoad,
  showCallToAction,
  callToAction,
}: MediaAndTextProps) => {
  const imageSrc = `${__ASSETS_COMMON_IMAGES_URL__}${imageSource}`;
  const videoSrc = `${__ASSETS_COMMON_IMAGES_URL__}${videoSource}`;

  return (
    <BuilderSection className="flex justify-center">
      <div className="flex-nowrap md:flex-row flex flex-col items-center w-full gap-8">
        <div
          className={`${
            mediaFirst ? 'md:order-1' : 'md:order-2'
          } md:w-1/2 w-full px-4 md:px-0`}
        >
          <div className="aspect-w-16 aspect-h-9 rounded-3xl overflow-hidden">
            {videoSource && (
              <video
                autoPlay={true}
                className="lg:block absolute inset-0 hidden object-cover w-full h-full bg-gray-400"
                loop={true}
                muted={true}
                playsInline={true}
                poster={imageSrc}
                preload="auto"
              >
                <source src={videoSrc} type="video/webm" />
                <source src={videoSrc} type="video/mp4" />
                <ProgressiveImage src={imageSrc} alt="" />
              </video>
            )}

            <ProgressiveImage
              className={`absolute inset-0 object-cover w-full h-full ${
                videoSource ? 'lg:hidden' : ''
              }`}
              src={imageSrc}
              height="280"
              width="488"
              shouldLazyLoad={lazyLoad ? lazyLoad : false}
              alt=""
            />
          </div>
        </div>

        <div
          className={`${
            mediaFirst ? 'md:order-2' : 'md:order-1'
          } md:w-1/2 w-full px-4 md:px-0`}
        >
          <div className="md:mx-0 gap-y-4 flex flex-col items-start justify-center mx-6">
            <h3 className={`text-2xl font-bold ${headingColor}`}>{heading}</h3>
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              className="text-base font-normal"
            ></div>
            <CallToAction
              showCallToAction={showCallToAction}
              {...callToAction}
            />
          </div>
        </div>
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(MediaAndText, {
  name: 'Media And Text (Legacy)',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Test heading',
    },
    {
      name: 'headingColor',
      type: 'string',
      defaultValue: 'text-black',
      enum: textColorOptions,
    },
    {
      name: 'content',
      type: 'richText',
      defaultValue: `I wanna hold 'em like they do in Texas, please
                Fold 'em, let 'em hit me, raise it, baby, stay with me (I love it)
                Love game intuition, play the cards with spades to start
                And after he's been hooked, I'll play the one that's on his heart`,
      helperText: '320 character limit recommended',
    },
    {
      name: 'imageSource',
      type: 'string',
      required: true,
      defaultValue: '/media-page/redesigned-footage/footage-sizzle.jpg',
      helperText: "Path to image asset, relative to '/assets/common/images'",
    },
    {
      name: 'videoSource',
      type: 'string',
      defaultValue: '/media-page/redesigned-footage/footage-sizzle.webm',
      helperText: "Path to image asset, relative to '/assets/common/images'",
    },
    {
      name: 'mediaFirst',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'lazyLoad',
      type: 'boolean',
      defaultValue: true,
    },
    ...callToActionInputs,
  ],
});
