import { Builder } from '@builder.io/react';
import React from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import {
  lazyLoadDefaultConfig,
  opacityOptions,
} from '../../../builder-io/constants';
import { FontFamily } from '../../utils/headerAlignmentFontInputs';
import { getBackgroundStyles } from '../blocks/styleUtils';
import BuilderSection from '../layouts/BuilderSection';

interface videoArticles {
  videoArticleName: string;
  videoArticleHeading: string;
  videoArticleDescription?: string;
  videoId: string;
}

interface Opacity {
  percentage: number;
}

interface VideoArticlesProps {
  heading: string;
  fontFamily: FontFamily;
  videoArticles: videoArticles[];
  darkMode: boolean;
  useOpaqueBackground: boolean;
  backgroundImageSrc: string;
  backgroundImageSrcAlt?: string;
  lazyloadImage: boolean;
  opacity?: Opacity;
}

const VideoArticles = ({
  heading,
  fontFamily,
  videoArticles,
  darkMode,
  useOpaqueBackground,
  backgroundImageSrc,
  backgroundImageSrcAlt = '',
  lazyloadImage,
  opacity,
}: VideoArticlesProps) => {
  return (
    <BuilderSection
      bgClassNames={getBackgroundStyles(darkMode, backgroundImageSrc)}
      heading={heading}
      headingClassNames={`${fontFamily} text-5xl text-left`}
    >
      <div>
        {useOpaqueBackground && (
          <div
            style={{ opacity: (opacity && opacity.percentage) || 0.3 }}
            className="absolute inset-0 w-full h-full bg-gray-900"
          ></div>
        )}
        <div className="inline absolute inset-0 w-full flex flex-col -z-1">
          <ProgressiveImage
            src={backgroundImageSrc}
            alt={backgroundImageSrcAlt}
            shouldLazyLoad={lazyloadImage}
          />
        </div>
        <div className="space-y-4 justify-center">
          <div className="md:grid-cols-3 grid grid-cols-1 gap-8 mx-auto mb-8">
            {videoArticles.map(
              (
                {
                  videoArticleName,
                  videoArticleHeading,
                  videoArticleDescription,
                  videoId,
                },
                index
              ) => (
                <div className="text-left">
                  <div
                    key={`article-${index}-${videoArticleName}`}
                    className="overflow-hidden rounded-lg"
                  >
                    <div className="aspect-w-16 aspect-h-9">
                      <iframe
                        className="w-full"
                        width="332"
                        height="184"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        title="Youtube video player"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                  {videoArticleHeading && (
                    <div
                      className="font-semibold text-base tracking-widest pt-4 pb-2"
                      dangerouslySetInnerHTML={{ __html: videoArticleHeading }}
                    ></div>
                  )}
                  <div
                    className={'font-bold text-2xl m-0 pb-1'}
                    dangerouslySetInnerHTML={{ __html: videoArticleName }}
                  ></div>
                  <div
                    className={'font-normal text-base m-0'}
                    dangerouslySetInnerHTML={{
                      __html: videoArticleDescription,
                    }}
                  ></div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </BuilderSection>
  );
};

Builder.registerComponent(VideoArticles, {
  name: 'Video Articles',
  inputs: [
    {
      name: 'heading',
      type: 'string',
      required: true,
      defaultValue: 'Video Articles',
    },
    {
      name: 'darkMode',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'useOpaqueBackground',
      type: 'boolean',
      required: false,
      defaultValue: false,
    },
    {
      name: 'opacity',
      type: 'object',
      defaultValue: {
        percentage: 0.3,
      },
      showIf: (options) => options.get('useOpaqueBackground'),
      subFields: [
        {
          name: 'percentage',
          type: 'string',
          required: true,
          enum: opacityOptions,
        },
      ],
    },
    {
      name: 'backgroundImageSrc',
      required: false,
      type: 'text',
      helperText:
        "Path to background image asset, relative to '/assets/common/images'",
    },
    {
      name: 'backgroundImageSrcAlt',
      type: 'string',
      required: false,
      helperText: 'Background Image alternative text',
    },
    {
      name: 'videoArticles',
      type: 'list',
      required: true,
      defaultValue: [
        {
          videoArticleName: 'Video Article Name',
          videoArticleHeading: 'Video Article Heading',
          videoId: 'JtitcwwEl-A',
          videoArticleDescription: 'Description',
        },
        {
          videoArticleName: 'Video Article Name',
          videoArticleHeading: 'Video Article Heading',
          videoId: 'JtitcwwEl-A',
          videoArticleDescription: 'Description',
        },
        {
          videoArticleName: 'Video Article Name',
          videoArticleHeading: 'Video Article Heading',
          videoId: 'JtitcwwEl-A',
          videoArticleDescription: 'Description',
        },
      ],
      subFields: [
        {
          name: 'videoId',
          type: 'string',
          defaultValue: 'JtitcwwEl-A',
          helperText: 'VideoId from Youtube',
        },
        {
          name: 'videoArticleHeading',
          type: 'string',
          defaultValue: '[Article Heading]',
          helperText: 'Episode Number and Timing of a Video Article',
        },
        {
          name: 'videoArticleName',
          type: 'string',
          defaultValue: '[Article Name]',
          helperText: 'Name of a Video Article',
        },
        {
          name: 'videoArticleDescription',
          type: 'richText',
          defaultValue: '[Article Description]',
          helperText: 'Description of Video Article',
        },
      ],
    },
    {
      ...lazyLoadDefaultConfig,
    },
  ],
});
